import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UIService, DialogDataForModalView } from 'src/app/shared/services/ui.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { SnackBar, SnackBarType } from 'src/app/shared/components/snackbar/entities/snackbar';
import { ValidationService } from 'src/app/shared/services/validation.service';
import { LinkedIn } from 'src/app/linkedin/entities/linkedin';
import { LinkedInService } from 'src/app/linkedin/service/linkedin.service';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/auth/login/login.service';
import { Users } from 'src/app/auth/entities/user';
import moment, { lang } from 'moment';
import { AuthAppService } from 'src/app/shared/services/auth-app.service';
import { FormatMatDatepicker } from 'src/app/shared/format-datepicker';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { SharedLinkedInService } from 'src/app/linkedin/service/shared.linkedin.service';
import { APIService } from '../../../shared/services/api.service';
import { CommonDropdownService } from '../../../shared/services/common.dropdown.service';
import { TemplateSubcategory } from '../../entities/templateSubcategory';
const nameTextChanged = new Subject<string>();
const companyTextChanged = new Subject<string>();
const cityTextChanged = new Subject<string>();

@Component({
  selector: 'add-edit-templete-subcategory',
  templateUrl: './add-edit-templete-subcategory.component.html',
  styleUrls: ['./add-edit-templete-subcategory.component.scss']
})

export class AddEditTempleteSubcategoryComponent implements OnInit {

  templateSubcategoryFormGroup: FormGroup;
  _templateSubcategoryModal: TemplateSubcategory;
  isEdit: boolean = false;
  _templateSubcategoryForSave: TemplateSubcategory;
  isDisabledTemplateSubcategory = false;

  constructor(
    private sharedLinkedInService: SharedLinkedInService,
    private formatDatepicker: FormatMatDatepicker,
    private _formBuilder: FormBuilder,
    private linkedinService: LinkedInService,
    public commonUIService: UIService,
    private uIService: UIService,
    private route: Router,
    private loginServic: LoginService,
    private validationService: ValidationService,
    private _apiService: APIService,
    private commonDropdownService: CommonDropdownService,
    public dialogRef: MatDialogRef<AddEditTempleteSubcategoryComponent>,
    @Inject(MAT_DIALOG_DATA) public templateSubcategoryData: DialogDataForModalView,
    private authAppService: AuthAppService) {
  }

  ngOnInit() {
    this.initFormGroup();
    this._templateSubcategoryModal = new TemplateSubcategory();
    this._templateSubcategoryForSave = new TemplateSubcategory();
    this.setTemplateSubcategoryDataForEditView();
  }

  initFormGroup() {
    this.templateSubcategoryFormGroup = this._formBuilder.group({
      subcategory: ['', [Validators.required, this.validationService.noWhitespaceValidator]],
    })
  }

  setTemplateSubcategoryDataForEditView() {
    if (this.templateSubcategoryData.data.id) {
      this._templateSubcategoryModal = Object.assign({}, this.templateSubcategoryData.data).modalData;
      this._templateSubcategoryModal.categoryId = this.templateSubcategoryData.data.modalData.categoryId; 
      this.isEdit = true;
    } else {
      this._templateSubcategoryModal.categoryId = this.templateSubcategoryData.data.modalData.id;
      this.isEdit = false;
    }
  }

  async submit(templateSubcategory) {
    if (this.templateSubcategoryFormGroup.invalid) {
      this.isDisabledTemplateSubcategory = false;
      return;
    }
    if (this.isEdit) {
      this.updateTemplateSubcategory(templateSubcategory.id, templateSubcategory);
    }
    else {

      this.saveTemplateSubcategoryData(templateSubcategory);
    }
  }

  saveTemplateSubcategoryData(templateSubcategoryObj) {
    this.isDisabledTemplateSubcategory = true;
    this.linkedinService.saveTempleteSubcategoryData(templateSubcategoryObj).subscribe(
      (res: { isSuccsess: any; }) => {
        if (res.isSuccsess) {
          var snack = new SnackBar(SnackBarType.success, "Successfully saved", "", 2000);
          this.uIService.openSnackBar(snack);
          this.closeDialog();
          this.linkedinService.refreshCategoryList.emit(true);
        }
        else {
          snack = new SnackBar(SnackBarType.success, "Error on save", "", 2000);
          this.uIService.openSnackBar(snack);
        }
      },
      () => { console.error(); this.closeDialog(); });
  }

  updateTemplateSubcategory(id: any, obj) {
    this.linkedinService.updateTempleteSubcategory(id, obj).subscribe(
      (res: { isSuccsess: any; }) => {
        if (res.isSuccsess) {
          var snack = new SnackBar(SnackBarType.success, "Successfully updated", "", 2000);
          this.uIService.openSnackBar(snack);
          this.closeDialog();
          this.linkedinService.refreshCategoryList.emit(true);
        }
        else {
          snack = new SnackBar(SnackBarType.success, "Error on update", "", 5000);
          this.uIService.openSnackBar(snack);
        }
      },
      () => { console.error(); this.closeDialog(); });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
