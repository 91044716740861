import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UIService, DialogDataForModalView } from 'src/app/shared/services/ui.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { SnackBar, SnackBarType } from 'src/app/shared/components/snackbar/entities/snackbar';
import { ValidationService } from 'src/app/shared/services/validation.service';
import { LinkedIn } from 'src/app/linkedin/entities/linkedin';
import { LinkedInService } from 'src/app/linkedin/service/linkedin.service';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/auth/login/login.service';
import { Users } from 'src/app/auth/entities/user';
import moment, { lang } from 'moment';
import { AuthAppService } from 'src/app/shared/services/auth-app.service';
import { FormatMatDatepicker } from 'src/app/shared/format-datepicker';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { SharedLinkedInService } from 'src/app/linkedin/service/shared.linkedin.service';
import { APIService } from '../../../shared/services/api.service';
import { CommonDropdownService } from '../../../shared/services/common.dropdown.service';
import { TemplateCategory } from '../../entities/templateCategory';
const nameTextChanged = new Subject<string>();
const templateCategoryTextChanged = new Subject<string>();
const cityTextChanged = new Subject<string>();

@Component({
  selector: 'add-edit-templete-category',
  templateUrl: './add-edit-templete-category.component.html',
  styleUrls: ['./add-edit-templete-category.component.scss']
})

export class AddEditTempleteCategoryComponent implements OnInit {

  templateCategoryFormGroup: FormGroup;
  _templateCategoryModal: TemplateCategory;
  isEdit: boolean = false;
  _templateCategoryForSave: TemplateCategory;
  isDisabledTemplateCategory = false;

  constructor(
    private sharedLinkedInService: SharedLinkedInService,
    private formatDatepicker: FormatMatDatepicker,
    private _formBuilder: FormBuilder,
    private linkedinService: LinkedInService,
    public commonUIService: UIService,
    private uIService: UIService,
    private route: Router,
    private loginServic: LoginService,
    private validationService: ValidationService,
    private _apiService: APIService,
    private commonDropdownService: CommonDropdownService,
    public dialogRef: MatDialogRef<AddEditTempleteCategoryComponent>,
    @Inject(MAT_DIALOG_DATA) public templateCategoryData: DialogDataForModalView,
    private authAppService: AuthAppService) {
  }

  ngOnInit() {
    this.initFormGroup();
    this._templateCategoryModal = new TemplateCategory();
    this._templateCategoryForSave = new TemplateCategory();
    this.setTemplateCategoryDataForEditView();
  }

  initFormGroup() {
    this.templateCategoryFormGroup = this._formBuilder.group({
      category: ['', [Validators.required, this.validationService.noWhitespaceValidator]],
    })
  }

  setTemplateCategoryDataForEditView() {
    if (this.templateCategoryData.data.id) {
      this._templateCategoryModal = Object.assign({}, this.templateCategoryData.data).modalData;
      this.isEdit = true;
    } else
      this.isEdit = false;
  }

  async submit(templateCategory) {
    if (this.templateCategoryFormGroup.invalid) {
      this.isDisabledTemplateCategory = false;
      return;
    }
    if (this.isEdit) {
      this.updateTemplateCategory(templateCategory.id, templateCategory);
    }
    else {

      this.saveTemplateCategoryData(templateCategory);
    }
  }

  saveTemplateCategoryData(templateCategoryObj) {
    this.isDisabledTemplateCategory = true;
    this.linkedinService.saveTempleteCategoryData(templateCategoryObj).subscribe(
      (res: { isSuccsess: any; }) => {
        if (res.isSuccsess) {
          var snack = new SnackBar(SnackBarType.success, "Successfully saved", "", 2000);
          this.uIService.openSnackBar(snack);
          this.closeDialog();
          this.linkedinService.refreshCategoryList.emit(true);
        }
        else {
          snack = new SnackBar(SnackBarType.success, "Error on save", "", 2000);
          this.uIService.openSnackBar(snack);
        }
      },
      () => { console.error(); this.closeDialog(); });
  }

  updateTemplateCategory(id: any, obj) {
    this.linkedinService.updateTempleteCategory(id, obj).subscribe(
      (res: { isSuccsess: any; }) => {
        if (res.isSuccsess) {
          var snack = new SnackBar(SnackBarType.success, "Successfully updated", "", 2000);
          this.uIService.openSnackBar(snack);
          this.closeDialog();
          this.linkedinService.refreshCategoryList.emit(true);
        }
        else {
          snack = new SnackBar(SnackBarType.success, "Error on update", "", 5000);
          this.uIService.openSnackBar(snack);
        }
      },
      () => { console.error(); this.closeDialog(); });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
