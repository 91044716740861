import { Injectable } from '@angular/core';
import { UIService } from './ui.service';
import { APIService } from './api.service';
import API from './api.routes.json'
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import DEVICON from '../../../../node_modules/devicon/devicon.json';
import { ClientService } from 'src/app/client/services/client.service';
import { InventoryService } from 'src/app/inventory/service/inventoryService';
import { SubscriptionType } from 'src/app/client-product/entities/subscriptionType';

@Injectable({
  providedIn: 'root'
})
export class CommonDropdownService {

  public _clientStatusList: any = [];
  public regionList: any = [];
  public _referralSourceList: any = [];
  public _referralSourceDropdownList: any = [];
  public _actionTypeList: any = [];
  public _projectStatusList: any = [];
  public _userList: any = [];
  public _technologiesList: any = [];
  public _deviconList: any = [];
  public _taskTypeList: any = [];
  public _regionDropDownList: any = [];
  public _documentTypeList: any = [];
  public _positionlist: any = [];
  public _provinceDropDown: any = [];
  public _inactiveProjectList: any = [];
  public _seriviceTypeList: any = [];
  public _inventoryList: any = [];
  public _inventoryStateList: any = [];
  public _activeClientStatusList: any = [];
  public _providerList: any = [];
  public _currencyList: any = [];
  public _countryList: any = [];
  public _roleList: any = [];
  public _companyList: any = [];
  public _inventoryMiscellaneousTypeList: any = [];
  public _departmentlist: any = [];
  public _designationlist: any = [];
  public _letterTypeList: any = [];
  public _closeReasonList: any = [];
  public _statusList: any = [];
  public _companyListForClient: any = [];
  public _clientContactList: any = [];
  public _estimationPhaseContactList: any = [];
  public _documentTypeListForCRM: any = [];
  public _leaveType: any = [];

  public _inventoryLaptopStorageTypeList: any = [];
  public _inventoryLaptopScreenSizeList: any = [];
  public _inventoryLaptopResolutionList: any = [];
  public _inventoryCpuTypeList: any = [];
  public _inventoryLaptopOSTypeList: any = [];
  public _inventoryMouseType: any = [];
  public _inventoryKeyBoardTypeList: any = [];
  public _inventoryConditionsList: any = [];

  public _inventoryMobileDeviceTypeList: any = [];
  public _inventoryMobileOSTypeList: any = [];

  public _inventoryMonitorTypeList: any = [];
  public _inventoryMonitorScreenSizeList: any = [];
  public _inventoryMonitorScreenResolutionList: any = [];
  public _inventoryMonitorOsList: any = [];

  public _HrmInsuranceTypeList: any = [];
  public _HrmLeaverTypeList: any = [];

  public subscriptionTypeList:SubscriptionType[];

  constructor(
    private _apiService: APIService,
    public _uIService: UIService,
    public clientService: ClientService,
    public _inventoryService:InventoryService) { }

  public LoadClientStatusList() {
    this._apiService.get(API.clientStatus.listAll)
      .subscribe(response => {
        this._clientStatusList = response.data;
      }, err => {
        console.error(err)
      }, () => { });
  }

  public LoadInventoryDropdownList() {
    this._apiService.get(API.inventoryMetaData.list)
      .subscribe(response => {
        this._inventoryLaptopStorageTypeList = response.data.laptopStorage;
        this._inventoryLaptopScreenSizeList = response.data.laptopScreenSize;
        this._inventoryLaptopResolutionList = response.data.laptopScreenResolution;
        this._inventoryCpuTypeList = response.data.laptopCPU;
        this._inventoryLaptopOSTypeList = response.data.laptopOS;
        this._inventoryMouseType = response.data.laptopMouseType;
        this._inventoryKeyBoardTypeList = response.data.laptopKeyboardType;
        this._inventoryConditionsList = response.data.commonCondition;
        this._inventoryMobileDeviceTypeList = response.data.mobileDeviceType;
        this._inventoryMobileOSTypeList = response.data.mobileDeviceOS;
        this._inventoryMonitorTypeList = response.data.monitorType;
        this._inventoryMonitorScreenSizeList = response.data.monitorScreenSize;
        this._inventoryMonitorScreenResolutionList = response.data.monitorResolution;
        this._inventoryMiscellaneousTypeList = response.data.miscellaneousType;
      }, err => {
        console.error(err)
      }, () => { });
  }

  public LoadHrmDropdownList(){
    this._apiService.get(API.hrmMetaData.list)
      .subscribe(response => {
        this._HrmInsuranceTypeList = response.data.laptopStorage;
        this._HrmLeaverTypeList = response.data.laptopScreenSize;
      }, err => {
        console.error(err)
      }, () => { });
  }

  public LoadAddClientData() {
    this.LoadProvinceList();

    // Load others
    this._apiService.get(API.client.loadAddClientData)
      .subscribe(response => {
        let d = response.data;
        this._referralSourceList = d.referrals;
        this._activeClientStatusList = d.status;
        this._regionDropDownList = d.regions;
        this._seriviceTypeList = d.serviceTypes;
        this._closeReasonList = d.closeReasons;
      }, err => {
        console.error(err)
      }, () => { this.setReferralSourceDropdownList(this._referralSourceList) });
  }

  public LoadClientActiveStatusList(): Observable<any> {
    return this._apiService.get(API.clientStatus.ListActive).pipe(map(res => res.data));
  }

  public LoadInactiveProjectList(clientId) {
    this.clientService.getInactiveProjectListAll(clientId)
      .subscribe(response => {
        this._inactiveProjectList = response.data;
      })
  }

  public LoadRegionList() {
    this._apiService.get(API.regionType.ListActive)
      .subscribe(response => {
        this._regionDropDownList = response.data;

      });
  }

  public LoadReferralSourceList() {
    this._apiService.get(API.referralSource.GetActiveReferralSource)
      .subscribe(response => {
        this._referralSourceList = response.data;
      }, err => {
        console.error(err)
      }, () => { this.setReferralSourceDropdownList(this._referralSourceList) });
  }

  private setReferralSourceDropdownList(referralSourceList) {
    let list = referralSourceList ? referralSourceList : [];
    this._referralSourceDropdownList = list.length > 0 ? list.filter(x => x.parentId == 0) : [];
    this._referralSourceDropdownList.forEach(ele => {
      ele.referrerList = list.filter(x => x.parentId == ele.id);
    });
  }

  public LoadActionTypeList() {
    this._apiService.get(API.actionType.GetActiveActionType)
      .subscribe(response => {
        this._actionTypeList = response.data;
      }, err => {
        console.error(err)
      }, () => { });
  }
  public LoadProjectStatusList() {  
    this._apiService.get(API.projectStatus.listAll)
      .subscribe(response => {       
        this._projectStatusList = response.data;        
      }, err => {
        console.error(err)
      }, () => { });
  }

  public LoadUserList(isActiveOnly:boolean=false) {
    //NOTE : When fetching the users the URL parameter 'isActiveOnly' is passed to determine whether to get active users only or not
    this._apiService.get(`${API.user.getAllUsers}/${isActiveOnly}`)
      .subscribe(response => {       
        this._userList = response.data;   
      }, err => {
        console.error(err);
      }, () => { });
  }  

  public LoadUserListAsObservable(): Observable<any> {
    return this._apiService.get(API.user.getAllUsers).pipe(map(res => res.data));   
  } 

  public LoadTechnologyList(): Observable<any> {
    return this._apiService.get(API.technologies.ListActive).pipe(map(res => res.data));
  }

  public LoadDeviconsList(): Observable<any> {
    this._deviconList = DEVICON;
    return of(this._deviconList);
  }

  public LoadTaskTypeList() {
    this._apiService.get(API.taskType.ListActive)
      .subscribe(response => {
        this._taskTypeList = response.data;
        console.log(response.data)
      }, err => {
        console.error(err)
      }, () => { });
  }

  public LoadPositionList() {
    this._apiService.get(API.position.ListActive)
      .subscribe(response => {
        this._positionlist = response.data;
      }, err => {
        console.error(err)
      }, () => { });
  }

  public LoadDocumentTypeList() {
    this._apiService.get(API.documentType.ListActive)
      .subscribe(response => {
        this._documentTypeList = response.data;
      }, err => {
        console.error(err)
      }, () => { });
  }

  public LoadProvinceList() {
    this._provinceDropDown = [
      { value: 'Alava', viewValue: 'Alava' },
      { value: 'Albacete', viewValue: 'Albacete' },
      { value: 'Alicante', viewValue: 'Alicante' },
      { value: 'Almeria', viewValue: 'Almeria' },
      { value: 'Andorra', viewValue: 'Andorra' },
      { value: 'Asturias', viewValue: 'Asturias' },
      { value: 'Avila', viewValue: 'Avila' },
      { value: 'Badajoz', viewValue: 'Badajoz' },
      { value: 'Baleares', viewValue: 'Baleares' },
      { value: 'Barcelona', viewValue: 'Barcelona' },
      { value: 'Burgos', viewValue: 'Burgos' },
      { value: 'Caceres', viewValue: 'Caceres' },
      { value: 'Cadiz', viewValue: 'Cadiz' },
      { value: 'Cantabria', viewValue: 'Cantabria' },
      { value: 'Castellon', viewValue: 'Castellon' },
      { value: 'Ceuta', viewValue: 'Ceuta' },
      { value: 'Ciudad Real', viewValue: 'Ciudad Real' },
      { value: 'Cordoba', viewValue: 'Cordoba' },
      { value: 'Cuenca', viewValue: 'Cuenca' },
      { value: 'Girona', viewValue: 'Girona' },
      { value: 'Granada', viewValue: 'Granada' },
      { value: 'Guadalajara', viewValue: 'Guadalajara' },
      { value: 'Guipuzcoa', viewValue: 'Guipuzcoa' },
      { value: 'Huelva', viewValue: 'Huelva' },
      { value: 'Huesca', viewValue: 'Huesca' },
      { value: 'Jaen', viewValue: 'Jaen' },
      { value: 'La Coruña', viewValue: 'La Coruña' },
      { value: 'La Rioja', viewValue: 'La Rioja' },
      { value: 'Las Palmas', viewValue: 'Las Palmas' },
      { value: 'Leon', viewValue: 'Leon' },
      { value: 'Lleida', viewValue: 'Lleida' },
      { value: 'Lugo', viewValue: 'Lugo' },
      { value: 'Madrid', viewValue: 'Madrid' },
      { value: 'Malaga', viewValue: 'Malaga' },
      { value: 'Melilla', viewValue: 'Melilla' },
      { value: 'Murcia', viewValue: 'Murcia' },
      { value: 'Navarra', viewValue: 'Navarra' },
      { value: 'Orense', viewValue: 'Orense' },
      { value: 'Palencia', viewValue: 'Palencia' },
      { value: 'Pontevedra', viewValue: 'Pontevedra' },
      { value: 'Salamanca', viewValue: 'Salamanca' },
      { value: 'Santa Cruz de Tenerife', viewValue: 'Santa Cruz de Tenerife' },
      { value: 'Segovia', viewValue: 'Segovia' },
      { value: 'Sevilla', viewValue: 'Sevilla' },
      { value: 'Soria', viewValue: 'Soria' },
      { value: 'Tarragona', viewValue: 'Tarragona' },
      { value: 'Teruel', viewValue: 'Teruel' },
      { value: 'Toledo', viewValue: 'Toledo' },
      { value: 'Valencia', viewValue: 'Valencia' },
      { value: 'Valladolid', viewValue: 'Valladolid' },
      { value: 'Vizcaya', viewValue: 'Vizcaya' },
      { value: 'Zamora', viewValue: 'Zamora' },
      { value: 'Zaragoza', viewValue: 'Zaragoza' }
    ]
  }

  public LoadServiceTypeList(isEdit: boolean = false) {
    this._apiService.get(API.serviceType.listActive)
      .subscribe(response => {
        this._seriviceTypeList = response.data;
      }, err => {
        console.error(err)
      }, () => { });
  }

  public LoadInventoryList() {
    this._inventoryList = [
      { id: 1, value: 'Laptop', viewValue: 'Laptop' },
      { id: 2, value: 'Mobile Devices', viewValue: 'Mobile Devices' },
      { id: 3, value: 'Monitors', viewValue: 'Monitors' },
      { id: 4, value: 'Miscellaneous', viewValue: 'Miscellaneous' },
      { id: 5, value: 'SIMM Card', viewValue: 'SIM Card' },
      { id: 6, value: 'Providers', viewValue: 'Providers' },
      { id: 7, value: 'Furniture', viewValue: 'Furniture' }
    ]
  }

  public LeaveType() {
    this._leaveType = [
      { id: 1, value: 'Casual leave'  },
      { id: 2, value: 'Annual leave' },
      { id: 3, value: 'Sick leave' },
    ]
  }

  public LoadInventoryStateDropdown() {
    this._inventoryStateList = [
      { value: 0, viewValue: 'Available' },
      { value: 1, viewValue: 'Inuse' },
      { value: 2, viewValue: 'Damaged' },
      { value: 3, viewValue: 'Lost' }
    ]
  }
  public LoadInventoryCpuTypDropdown() {
    this._inventoryCpuTypeList = [
      { value: 'i3', viewValue: 'i3' },
      { value: 'i5', viewValue: 'i5' },
      { value: 'i7', viewValue: 'i7' },
      { value: 'i9', viewValue: 'i9' },
    ]
  }
  public LoadInventoryMouseTypDropdown() {
    this._inventoryMouseType = [
      { value: 'Wireless', viewValue: 'Wireless' },
      { value: 'USB', viewValue: 'USB' },
    ]
  }
  public LoadTaskTypeListForHeader(): Observable<any> {
    return this._apiService.get(API.taskType.getTaskTypeForHeader).pipe(map(res => res.data));
  }

  public LoadActionTypeListForHeader(): Observable<any> {
    return this._apiService.get(API.actionType.getActionTypeForHeader).pipe(map(res => res.data));
  }



  public LoadProviderList() {
    this._apiService.get(API.provider.listAll)
      .subscribe(response => {
        this._providerList = response.data;
      }, err => {
        console.error(err)
      }, () => { });
  }

  public LoadCurrencyList() {
    this._apiService.get(API.currency.listAll)
      .subscribe(response => {
        this._currencyList = response.data;
      }, err => {
        console.error(err)
      }, () => { });
  }

  public LoadCountryList() {
    this._apiService.get(API.country.listAll)
      .subscribe(response => {
        this._countryList = response.data;
      }, err => {
        console.error(err)
      }, () => { });
  }

  public LoadRolesList() {
    this._apiService.get(API.role.listAll)
      .subscribe(response => {
        this._roleList = response.data;
      }, err => {
        console.error(err)
      }, () => { });
  }

  public LoadCompantList() {
    this._apiService.get(API.company.listAll)
      .subscribe(response => {
        this._companyList = response.data;
      }, err => {
        console.error(err)
      }, () => { });
  }

  public LoadCompantListForClient() {
    this._apiService.get(API.company.listAll)
      .subscribe(response => {
        if (response && response.data) {
          this._companyListForClient = response.data;
        }
      }, err => {
        console.error(err)
      }, () => { });
  }

  public LoadInventoryMiscellaneousTypeList() {
    this._inventoryMiscellaneousTypeList = [
      { value: 'MOUSE', viewValue: 'Mouse' },
      { value: 'ROUTER', viewValue: 'Router' },
      { value: 'EXTERNALHARDDRIVE', viewValue: 'External hard drive' },
      { value: 'PENDRIVE', viewValue: 'Pen drive' },
      { value: 'WEBCAMERA', viewValue: 'Web camera' },
      { value: 'HEADPHONE', viewValue: 'Headphone' },
      { value: 'KEYBOARD', viewValue: 'Keyboard' },
    ]
  }

  public LoadDepartmentList() {
    this._apiService.get(API.department.getAllDepartment)
      .subscribe(response => {
        this._departmentlist = response.data;
      }, err => {
        console.error(err)
      }, () => { });
  }

  public LoadDesignationList() {
    this._apiService.get(API.designation.getAllDesignation)
      .subscribe(response => {
        this._designationlist = response.data;
      }, err => {
        console.error(err)
      }, () => { });
  }

  public LoadLetterTypeList() {
    this._apiService.get(API.employeeLetters.getEmployeeLettersList)
      .subscribe(response => {
        this._documentTypeList = response.data;
      }, err => {
        console.error(err)
      }, () => { });
  }

  public LoadCloseReasonList() {
    this._apiService.get(API.closeReason.listAll)
      .subscribe(response => {
        this._closeReasonList = response.data;
      }, err => {
        console.error(err)
      }, () => { });
  }

  public LoadStatusList(type: string) {
    const url = this._apiService.getEndPoint("status.listAll", [["{type}", type]]);
    this._apiService.get(url)
      .subscribe(response => {
        this._statusList = response.data;
        let index1 = this._statusList.findIndex(s => s.text === '1x');
        if (index1 !== -1) {
          this._statusList.splice(index1, 1);
        }
        let index2 = this._statusList.findIndex(s => s.text === '2x');
        if (index2 !== -1) {
          this._statusList.splice(index2, 1);
        }
        let index3 = this._statusList.findIndex(s => s.text === '3x');
        if (index3 !== -1) {
          this._statusList.splice(index3, 1);
        }
      }, err => {
        console.error(err)
      }, () => { });
  }

  public LoadCompanyList(): Observable<any> {
    return this._apiService.get(API.company.listAll).pipe(map(res => res.data));
  }

  public LoadClientContactsByClientId(clientId: number) {
    const url = this._apiService.getEndPoint("clientContact.listByClientId", [["{clientId}", clientId]]);
    this._apiService.get(url)
      .subscribe(response => {
        this._clientContactList = response.data;
      }, err => {
        console.error(err)
      }, () => { });
  }

  public ListAllPhaseContactList() {
    this._apiService.get(API.estimationPhase.listAllContacts)
      .subscribe(response => {
        this._estimationPhaseContactList = response.data;
      }, err => {
        console.error(err)
      }, () => { });
  }

  public LoadDocumentTypeListForCRM() {
    this._apiService.get(API.typeOfDocument.listAllTypeOfDocument)
      .subscribe(response => {
        this._documentTypeListForCRM = response.data;
      }, err => {
        console.error(err)
      }, () => { });
  }

 //#region "Load subscription types"

  public loadAllSubscriptionTypes(): Observable<any> 
  {
    return this._apiService.get(API.subscriptionType.listAll);    
  }

  public loadActiveSubscriptionTypes(): Observable<any> 
  {
    return this._apiService.get(API.subscriptionType.listActive); 
  }

  ////#endregion
  
}
