import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UIService, DialogDataForModalView } from 'src/app/shared/services/ui.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { SnackBar, SnackBarType } from 'src/app/shared/components/snackbar/entities/snackbar';
import { ValidationService } from 'src/app/shared/services/validation.service';
import { LinkedIn } from 'src/app/linkedin/entities/linkedin';
import { LinkedInService } from 'src/app/linkedin/service/linkedin.service';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/auth/login/login.service';
import { Users } from 'src/app/auth/entities/user';
import { CommonDropdownService } from '../../../../shared/services/common.dropdown.service';
import moment, { lang } from 'moment';
import { AuthAppService } from 'src/app/shared/services/auth-app.service';
import { FormatMatDatepicker } from 'src/app/shared/format-datepicker';
import { LinkedinReasonPopupComponent } from '../linkedin-reason-popup/linkedin-reason-popup.component';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { SharedLinkedInService } from 'src/app/linkedin/service/shared.linkedin.service';
import { APIService } from '../../../../shared/services/api.service';
const nameTextChanged = new Subject<string>();
const companyTextChanged = new Subject<string>();
const cityTextChanged = new Subject<string>();
import API from '../../../../shared/services/api.routes.json';
import { LinkedinEmailModel } from '../../../entities/linkedinEmailModel';
import { SendEmailComponent } from '../email-popup/email-popup.component';

@Component({
  selector: 'app-add-edit-linkedin',
  templateUrl: './add-edit-linkedin.component.html',
  styleUrls: ['./add-edit-linkedin.component.scss']
})

export class AddEditLinkedInComponent implements OnInit {
  linkedinFormGroup: FormGroup;
  _linkedinModal: LinkedIn;
  isEdit: boolean = false;
  _linkedInForSave: LinkedIn;
  currentusers: Users;
  isDisabledLinkedIn = false;
  _loggedinUser: Users = new Users();
  autoFilteredNameList: any = [];
  autoFilteredCompanyList: any = [];
  autoFilteredCityList: any = [];
  lengthNumber: number;
  emailModel: LinkedinEmailModel;

  constructor(
    private sharedLinkedInService: SharedLinkedInService,
    private formatDatepicker: FormatMatDatepicker,
    private _formBuilder: FormBuilder,
    private linkedinService: LinkedInService,
    public commonUIService: UIService,
    private uIService: UIService,
    private route: Router,
    private loginServic: LoginService,
    private validationService: ValidationService,
    private _apiService: APIService,
    private commonDropdownService: CommonDropdownService,
    public dialogRef: MatDialogRef<AddEditLinkedInComponent>,
    @Inject(MAT_DIALOG_DATA) public linkedinData: DialogDataForModalView,
    private authAppService: AuthAppService) {
    this.currentusers = new Users();
    this.emailModel = new LinkedinEmailModel();
  }

  ngOnInit() {
    this.initFormGroup();
    this._linkedinModal = new LinkedIn();
    this._linkedInForSave = new LinkedIn();
    this.getCurrentUser();
    this.commonDropdownService.LoadProvinceList();
    this.commonDropdownService.LoadUserList();
    this.commonDropdownService.LoadCompantList();
    this.commonDropdownService.LoadCountryList();
    this.getLoggedinUser();
    this.setLinkedInDataForEditView();
    this.commonUIService.loadReminderDateList();
    this.subscriberReasonPoupForAdd();
    this.commonDropdownService.LoadStatusList("LinkedIn");
    nameTextChanged.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe((res) => {
      this.loadDataForAutoComplete(res);
    });

    companyTextChanged.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe((res) => {
      this.loadCompanyForAutoComplete(res);
    });

    cityTextChanged.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe((res) => {
      this.loadCityForAutoComplete(res);
    });
  }

  initFormGroup() {
    this.linkedinFormGroup = this._formBuilder.group({
      name: ['', [Validators.required, this.validationService.noWhitespaceValidator]],
      city: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email, this.validationService.noWhitespaceValidator]],
      comments: [''],
      statusId: ['', [Validators.required]],
      isLinkedIn: [''],
      country: [''],
      province: [''],
      userId: ['', [Validators.required]],
      entryDate: ['', [Validators.required]],
      reminderDate: [''],
      company: ['', [Validators.required]],
      phoneNumber: ['', [Validators.pattern(/^(\+\d{1,3}[- ]?)?\d{9}$/)]],
    })
  }

  public loadCountryList() {
    this._apiService.get(API.country.listAll)
      .subscribe(response => {
        if (response && response.data) {
          let _countryList = response.data;
          let country = _countryList.find(m => m.name == 'Spain');
          if (country != null) {
            this._linkedinModal.country = country.name;
          } else {
            this._linkedinModal.country = null;
          }
        }
      }, err => {
        console.error(err)
      }, () => { });
  }

  searchNameForAutoComplete(event) {
    nameTextChanged.next(event);
  }

  searchCompanyForAutoComplete(event) {
    companyTextChanged.next(event);
  }

  searchCityForAutoComplete(event) {
    cityTextChanged.next(event);
  }


  companySelected(event) {
    this._linkedinModal.company = event.option.value;
  }

  citySelected(event) {
    this._linkedinModal.city = event.option.value;
  }

  loadCityForAutoComplete(event) {
    let value = event.target.value === '' ? null : event.target.value;
    if (value && event.target.value.length >= 5) {
      this.autoFilteredCityList = this.linkedinService.commonLinkedList.filter(x => x.city != null && x.city.toLowerCase().indexOf(value.toLowerCase()) >= 0);
      this.autoFilteredCityList = this.autoFilteredCityList.filter(
        (thing, i, arr) => arr.findIndex(t => t.city === thing.city) === i
      );
    } else {
      this.autoFilteredCityList = [];
    }
  }

  loadCompanyForAutoComplete(event) {
    let array = this.linkedinService.commonLinkedList;
    let value = event.target.value === '' ? null : event.target.value;
    if (value && event.target.value.length >= 10) {
      this.autoFilteredCompanyList = array.filter(x => x.company != null && x.company.toLowerCase().indexOf(value.toLowerCase()) >= 0);
      this.autoFilteredCompanyList = this.autoFilteredCompanyList.filter(
        (thing, i, arr) => arr.findIndex(t => t.company === thing.company) === i
      );
    } else {
      this.autoFilteredCompanyList = [];
    }
  }
  loadDataForAutoComplete(event) {
    let value = event.target.value === '' ? null : event.target.value;
    if (value && event.target.value.length >= 10) {
      this.autoFilteredNameList = this.linkedinService.commonLinkedList.filter(x => x.name.toLowerCase().indexOf(value.toLowerCase()) >= 0);
      this.autoFilteredNameList = this.autoFilteredNameList.filter(
        (thing, i, arr) => arr.findIndex(t => t.name === thing.name) === i
      );
    } else {
      this.autoFilteredNameList = [];
    }
  }

  linkedInCheckEvent(event) {
    this._linkedinModal.isLinkedIn = event.checked;
  }

  getLoggedinUser() {
    this._loggedinUser = this.authAppService.getLoggedInUser();
    this._linkedinModal.userId = this._loggedinUser ? this._loggedinUser.id : null;
  }

  setLinkedInDataForEditView() {
    if (this.linkedinData.data) {
      this._linkedinModal = Object.assign({}, this.linkedinData.data).modalData;
      this.isEdit = true;
    } else {
      let today = moment();
      let date = moment(today);
      this._linkedinModal.userId = this._loggedinUser ? this._loggedinUser.id : null;
      this._linkedinModal.entryDate = date;
      this._linkedinModal.state = "1";
      this._linkedinModal.statusId = 1;
      this._linkedinModal.language = "ESP"
      this._linkedinModal.reminderDate = date;
      var _reminderDate = new Date(this._linkedinModal.reminderDate);
      _reminderDate.setDate(_reminderDate.getDate() + 7);
      this._linkedinModal.reminderDate = _reminderDate;
      this.loadCountryList();
    }
  }

  subscriberReasonPoupForAdd() {
    this.linkedinService.linkedReasonEmitForAdd.subscribe(data => {
      if (data) {
        this._linkedinModal.comments = !this._linkedinModal.comments ? data : this._linkedinModal.comments.concat('\n', data);
      }
    })
  }

  changeReminderDate(item) {
    this._linkedinModal.reminderDate = this.linkedinService.setDateValueToPicker(item)
    this._linkedinModal.reminderDateStr = this._linkedinModal.reminderDate ? this.formatDatepicker.ConvertDateFormatForSave(this._linkedinModal.reminderDate) : this._linkedinModal.reminderDate;

  }
  changeLinkedInState(event) {
    let today = moment();
    let date = moment(today);
    this._linkedinModal.reminderDate = date;
    this._linkedinModal.statusId = event.value
    var _reminderDate = new Date(this._linkedinModal.reminderDate);
    let stateId = event.value;
    switch (stateId) {
      case 1:
        _reminderDate.setDate(_reminderDate.getDate() + 7);
        break;
      case 4:
        _reminderDate = null;
        break;
      case 5:
        _reminderDate = null;
        this.OpenLinkedInReasonPopup('Comment');
        break;
      case 6:
        _reminderDate.setDate(_reminderDate.getDate() + 2);
        break;
      case 7:
        _reminderDate.setDate(_reminderDate.getDate() + 2);
        break;
      case 8:
        _reminderDate.setMonth(_reminderDate.getMonth() + 6);
        break;
      case 11:
        _reminderDate.setDate(_reminderDate.getDate() + 7);
        break;
      case 15:
        _reminderDate.setDate(_reminderDate.getDate() + 7);
        break;
      case 18:
        _reminderDate.setDate(_reminderDate.getDate() + 7);
        break;
      case 20:
        _reminderDate.setDate(_reminderDate.getDate() + 7);
        break;
      case 21:
        _reminderDate.setDate(_reminderDate.getDate() + 7);
        break;
    }

    if (_reminderDate) {
      if (_reminderDate.getDay() === 6) {
        _reminderDate.setDate(_reminderDate.getDate() + 2);
      } else if (_reminderDate.getDay() === 0) {
        _reminderDate.setDate(_reminderDate.getDate() + 1);
      }
    }
    this._linkedinModal.reminderDate = _reminderDate;
  }

  async submit(linkedIn: { id: any; }) {
    this._linkedinModal.reminderDateStr = this._linkedinModal.reminderDate ? this.formatDatepicker.ConvertDateFormatForSave(this._linkedinModal.reminderDate) : this._linkedinModal.reminderDate;
    //Don't remove this comment
    //if (this._linkedinModal.countryId > 0 || this._linkedinModal.countryId != null) {
    //  let country = this.commonDropdownService._countryList.find(m => m.id == this._linkedinModal.countryId);
    //  if (country != null) {
    //    this._linkedinModal.country = country.name;
    //  } else {
    //    this._linkedinModal.country = null;
    //  }
    //} else {
    //  if (this._linkedinModal.country != null) {
    //    this._linkedinModal.countryId = null;
    //    this._linkedinModal.country = this._linkedinModal.country;
    //  } else {
    //    this._linkedinModal.countryId = null;
    //    this._linkedinModal.country = null;
    //  }      
    //}
    if (this.linkedinFormGroup.invalid) {
      this.isDisabledLinkedIn = false;
      return;
    }
    if (this.isEdit) {
      this.updateLinkedIn(linkedIn.id, linkedIn);
    }
    else {
      this.saveLinkedInData(linkedIn);
    }
  }

  private OpenLinkedInReasonPopup(AddComment) {
    const dialogViewData = this.commonUIService.setDataForModalDialog(null, null, "Comment", null, null, null);
    this.commonUIService.openDialog(LinkedinReasonPopupComponent, dialogViewData);
  }

  onKey(numb) {
    if (numb.charAt(0) == "+") {
      this.lengthNumber = 12
    }
    else {
      this.lengthNumber = 9;
    }
  }

  saveLinkedInData(linkedInObj) {
    this.isDisabledLinkedIn = true;
    if (linkedInObj.state == 1)
      linkedInObj.state = "1";
    else if (linkedInObj.state == 2)
      linkedInObj.state = "2";
    else if (linkedInObj.state == 3)
      linkedInObj.state = "CONTACT";
    else if (linkedInObj.state == 4)
      linkedInObj.state = "CHAT";
    else if (linkedInObj.state == 5)
      linkedInObj.state = "OK";
    else if (linkedInObj.state == 6)
      linkedInObj.state = "NO";
    else if (linkedInObj.state == 7)
      linkedInObj.state = "STAND-BY";

    linkedInObj.companyId = this.sharedLinkedInService.linkedInCompanyId;
    this.linkedinService.saveLinkedInData(linkedInObj).subscribe(
      (res: { isSuccsess: any; }) => {
        if (res.isSuccsess) {
          var snack = new SnackBar(SnackBarType.success, "Successfully saved", "", 2000);
          this.uIService.openSnackBar(snack);
          this.closeDialog();
          this.linkedinService.linkedInRefresh(true);
        }
        else {
          snack = new SnackBar(SnackBarType.success, "Error on save", "", 5000);
          this.uIService.openSnackBar(snack);
        }
      },
      () => { console.error(); this.closeDialog(); });
  }

  updateLinkedIn(id, obj) {
    this.linkedinService.updateLinkedInObj(id, obj).subscribe(
      (res: { isSuccsess: any; }) => {
        if (res.isSuccsess) {
          var snack = new SnackBar(SnackBarType.success, "Successfully updated", "", 2000);
          this.uIService.openSnackBar(snack);
          this.closeDialog();
          this.linkedinService.linkedInRefresh(true);
        }
        else {
          snack = new SnackBar(SnackBarType.success, "Error on update", "", 5000);
          this.uIService.openSnackBar(snack);
        }
      },
      () => { console.error(); this.closeDialog(); });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  async getCurrentUser() {
    let jsonString = await this.loginServic.getSessionAppUser();
    this.currentusers = jsonString ? JSON.parse(jsonString) : "";
    if (this.currentusers.imageUrl == undefined) {
      this.currentusers.imageUrl = "assets/images/profile.jpg";
    }
  }

  selectLanguage(event) {
    if (this._linkedinModal.city) {
      if (this._linkedinModal.city.toLowerCase() == 'barcelona' || this._linkedinModal.city.toLowerCase() == 'tarragona' ||
        this._linkedinModal.city.toLowerCase() == 'lleida' || this._linkedinModal.city.toLowerCase() == 'girona') {
        this._linkedinModal.language = 'CAT';
      }
    }
    if (this._linkedinModal.country) {
      if (this._linkedinModal.country.toLowerCase() == 'spain') {
        this._linkedinModal.language = 'ESP';
      }
      else if (this._linkedinModal.country.toLowerCase() == 'andorra') {
        this._linkedinModal.language = 'CAT';
      }
    }
  }

  changeLanguage(language: string) {
    if (language) {
      this._linkedinModal.language = language;
    }
  }

  onOpenSendEmailPopup() {
    if (this._linkedinModal.email && this._linkedinModal.language) {
      let emailModalData = {
        category: 'LinkedIn',
        email: this._linkedinModal.email,
        language: this._linkedinModal.language
      };
      const dialogViewData = this.commonUIService.setDataForModalDialog(null, null, null, emailModalData, null, null);
      this.commonUIService.openDialog(SendEmailComponent, dialogViewData);
    } else {
      const snack = new SnackBar(SnackBarType.success, "Email and language should be filled", "", 5000);
      this.commonUIService.openSnackBar(snack);
    }
  }

  async OnSendEmail() {
    try {
      if (!this.emailModel.to) {
      }
    } catch (error) { console.log(error); } finally {
    }
  }
}
