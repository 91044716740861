import { Component, OnInit, ElementRef, Inject, ViewChild, ChangeDetectorRef } from '@angular/core';
import { CommonDropdownService } from 'src/app/shared/services/common.dropdown.service';
import { UIService, DialogDataForModalView } from 'src/app/shared/services/ui.service';
import { APIService } from 'src/app/shared/services/api.service';
import { ValidationService } from 'src/app/shared/services/validation.service';
import { FormatMatDatepicker } from 'src/app/shared/format-datepicker';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { HrmService } from '../../service/hrm.service';
import { Employee } from '../../entities/empoyee';
import { SnackBar, SnackBarType } from '../../../shared/components/snackbar/entities/snackbar';
import { Subject } from 'rxjs';

@Component({
  selector: 'add-employee',
  templateUrl: './add-employee.component.html',
  styleUrls: ['./add-employee.component.scss'],
  host: {
    "(window:paste)": "handlePaste( $event )"
  },
})

export class AddEmployeeComponent implements OnInit {
  employee: Employee;
  employeeFormGroup: FormGroup;
  lengthNumber: number = 12;
  employeeForSave: Employee;
  isDisabledEmployee = true;

  // region image
  imageDataViewForEdit: any;
  imageFront: any;
  imageFileNameFront: any;
  imageName: any;
  firstOpen: boolean = true;
  //

  private _unsubscribeAll: Subject<any>;

  constructor(public commonDropdownService: CommonDropdownService,
    public commonUIService: UIService,
    public _apiService: APIService,
    public hrmService: HrmService,
    public dialog: MatDialog,
    public fb: FormBuilder,
    public formatDatepicker: FormatMatDatepicker,
    private validationService: ValidationService,
    public dialogRef: MatDialogRef<AddEmployeeComponent>,
    @Inject(MAT_DIALOG_DATA) public currentconpanyData: DialogDataForModalView) {
    this.employee = new Employee();
    this.employeeForSave = new Employee();
    this._unsubscribeAll = new Subject();
  }

  ngOnInit() {
    this.commonDropdownService.LoadDesignationList();
    this.commonDropdownService.LoadDepartmentList();
    this.initEmployeeDetailForm();
  }

  initEmployeeDetailForm() {
    this.employeeFormGroup = this.fb.group({
      id: [''],
      firstName: ['', [Validators.required, this.validationService.noWhitespaceValidator]],
      lastName: ['', [this.validationService.noWhitespaceValidator]],
      employeeNumber: ['', [Validators.required, this.validationService.noWhitespaceValidator]],
      nic: [''],
      dob: [''],
      designation: [''],
      department: [''],
      jointDate: ['', [Validators.required]],
      email: ['', [Validators.email]],
      phone1: ['',[Validators.required,this.validationService.checkLimit(6,15)]],
      address: [''],
      phone2: ['',[this.validationService.checkLimit(6,15)]],
      relationShip: [''],
      emergencyContactPersonName: [''],
      isProbationCompleted: [false],
      isContractCompleted: [false]
    });
    if (this.currentconpanyData.data.modalData != null || this.currentconpanyData.data.modalData != undefined) {
      this.setValuesForEdit();
    }
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  onKey(numb) {
    if (numb.charAt(0) == "+") {

      this.lengthNumber = 15
    }
    else {
      this.lengthNumber = 15;
    }
  }

  setValuesForEdit() {
    this.employee = this.currentconpanyData.data.modalData;
    this.imageDataViewForEdit = this.employee.prolfileImage;
  }

  changeListenerFront(event: any): void {
    let files: any = event.target.files;
    this.employee.fileList = files;
    this.readThisFront(event.target);
  }

  readThisFront(inputValue: any): void {
    const file: File = inputValue.files[0];
    const myReader: FileReader = new FileReader();

    myReader.onloadend = (e) => {
      this.imageFront = myReader.result;
      this.imageFileNameFront = file.name;
      this.imageDataViewForEdit = this.imageFront;
    }
    myReader.readAsDataURL(file);
  }

  async submit(employeeModel) {
    this.isDisabledEmployee = false;
    if (this.employeeFormGroup.invalid) {
      this.isDisabledEmployee = true;
      return;
    }
    if (this.currentconpanyData.data.id > 0 && this.hrmService.isEmployeeEdit == true) {
      let employeeObj = Object.assign({}, employeeModel);
      employeeObj.dobStr = employeeObj.dob ? this.formatDatepicker.ConvertDateFormatForSave(employeeObj.dob) : employeeObj.dob;
      employeeObj.jointDateStr = employeeObj.jointDate ? this.formatDatepicker.ConvertDateFormatForSave(employeeObj.jointDate) : employeeObj.jointDate;
      this.updateEmployee(employeeObj);
    } else {
      let employeeObj = Object.assign({}, employeeModel);
      employeeObj.dobStr = employeeObj.dob ? this.formatDatepicker.ConvertDateFormatForSave(employeeObj.dob) : employeeObj.dob;
      employeeObj.jointDateStr = employeeObj.jointDate ? this.formatDatepicker.ConvertDateFormatForSave(employeeObj.jointDate) : employeeObj.jointDate;
      this.addEmployee(employeeObj);
    }
  }

  updateEmployee(employeeObj) {
    this.bindToModel(employeeObj);
    this.hrmService.updateEmployee(employeeObj.id, this.employeeForSave).subscribe(
      res => {
        if (res.isSuccsess) {
          this.isDisabledEmployee = true;
          if (this.employee.fileList && res.data.profileFileName) {
            this.uploadEmployeeProfileImage(res);
          }
          else {
            const snack = new SnackBar(SnackBarType.success, 'Successfully updated', '', 4000);
            this.commonUIService.openSnackBar(snack);
            let savedEmployee: any = res.data;
            this.closeDialog();
            this.employee.showEditSave = false;
            this.employee.isEdit = true;
            this.employeeFormGroup.disable();
            this.hrmService.refershListEmloyee.emit(savedEmployee);
            this.hrmService.refreshEmployee.emit(savedEmployee);
            this.hrmService.selectedEmployee.emit(savedEmployee);
          }
        } else {
          this.isDisabledEmployee = true;
          const snack = new SnackBar(SnackBarType.error, 'Error on save', '', 4000);
          this.commonUIService.openSnackBar(snack);
        }
      },
      () => { console.error(); this.isDisabledEmployee = true;});
  }

  addEmployee(employeeObj) {
    this.bindToModel(employeeObj);
    this.hrmService.addEmployee(this.employeeForSave).subscribe(
      res => {
        if (res.isSuccsess) {
          this.isDisabledEmployee = true;
          if (this.employee.fileList && res.data.profileFileName) {
            this.uploadEmployeeProfileImage(res);
          }
          else {
            const snack = new SnackBar(SnackBarType.success, 'Successfully saved', '', 4000);
            this.commonUIService.openSnackBar(snack);
            this.closeDialog();
            let savedEmployee: any = res.data;
            savedEmployee.isEdit = true;
            savedEmployee.showEditSave = false;
            this.hrmService.refershListEmloyee.emit(savedEmployee);
            this.hrmService.refreshEmployee.emit(savedEmployee);
            this.hrmService.selectedEmployee.emit(savedEmployee);
          }
        } else {
          const snack = new SnackBar(SnackBarType.error, 'Error on save', '', 4000);
          this.commonUIService.openSnackBar(snack);
          this.isDisabledEmployee = true;
        }
      },
      () => { console.error(); this.closeDialog(); this.isDisabledEmployee = true;});
  }

  bindToModel(employeeObj) {
    this.employeeForSave = Object.assign({}, employeeObj);
    this.employeeForSave.profileFileName = this.imageFileNameFront ? this.imageFileNameFront : this.employeeForSave.profileFileName;
    this.employeeForSave.profilePathForView = this.imageFront;
    if (this.currentconpanyData.data.companyId !== null) {
      this.employeeForSave.companyId = this.currentconpanyData.data.companyId ? this.currentconpanyData.data.companyId : 0;
    }
  }

  uploadEmployeeProfileImage(employee) {
    const formData: FormData = new FormData();
    formData.append('file', this.employee.fileList[0], employee.data.logo);
    formData.append('folderName', 'Employee');
    formData.append('id', employee.data.id.toString());
    formData.append('path', 'Profile');
    let savedEmployee: any = employee.data;
    this.hrmService.uploadEmployeeProfileImage(formData).subscribe(
      res => {
        if (+res == 200) {
          const snack = new SnackBar(SnackBarType.success, 'Successfully saved', '', 4000);
          this.commonUIService.openSnackBar(snack);
          this.closeDialog();
          savedEmployee.isEdit = true;
          savedEmployee.showEditSave = false;
          this.hrmService.refershListEmloyee.emit(savedEmployee);
          this.hrmService.refreshEmployee.emit(savedEmployee);
          this.hrmService.selectedEmployee.emit(savedEmployee);
        } else {
          const snack = new SnackBar(SnackBarType.error, 'Error on profile image upload', '', 4000);
          this.commonUIService.openSnackBar(snack);
          this.hrmService.refershListEmloyee.emit(savedEmployee);
          this.hrmService.refreshEmployee.emit(savedEmployee);
          this.hrmService.selectedEmployee.emit(savedEmployee);
        }
      },
      () => { console.error(); this.closeDialog(); });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
