import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UIService, DialogDataForModalView } from 'src/app/shared/services/ui.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { SnackBar, SnackBarType } from 'src/app/shared/components/snackbar/entities/snackbar';
import { ValidationService } from 'src/app/shared/services/validation.service';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/auth/login/login.service';
import { Users } from 'src/app/auth/entities/user';
import { CommonDropdownService } from '../../../shared/services/common.dropdown.service';
import moment from 'moment';
import { SharedHeaderService } from '../../../shared/services/shared.header.services';
import { isNumber } from 'util';
import { FormatMatDatepicker } from '../../../shared/format-datepicker';
import { ProductService } from '../../service/product.service';
import { ProductPack } from '../../entities/productPack';
import { SubscriptionType } from '../../entities/subscriptionType';

@Component({
  selector: 'app-add-edit-product-pack',
  templateUrl: './add-edit-product-pack.component.html',
  styleUrls: ['./add-edit-product-pack.component.scss']
})

export class AddEditProductPackComponent implements OnInit {

  isEdit: boolean = false;
  productPackFormGroup: FormGroup;
  _productPackModal: ProductPack;
  isDisabledProductPack: boolean = false;
  isDisabledSubscriptionType:boolean=false;
  subscriptionTypeList : SubscriptionType[];

  constructor(
    private _formBuilder: FormBuilder,
    public commonUIService: UIService,
    private uIService: UIService,
    private route: Router,
    private loginServic: LoginService,
    private validationSerivce: ValidationService,
    public sharedHeaderService: SharedHeaderService,
    private formatDatepicker: FormatMatDatepicker,
    public productService: ProductService,
    private commonDropdownService: CommonDropdownService,
    public dialogRef: MatDialogRef<AddEditProductPackComponent>,
    @Inject(MAT_DIALOG_DATA) public modalViewData: DialogDataForModalView) {
    this._productPackModal = new ProductPack();
  }

  ngOnInit() {
    this.initFormGroup();
    this.loadProductPackDataForEditView();   
  }

  initFormGroup() {
    this.productPackFormGroup = this._formBuilder.group({
      packName: [''],
      subscriptionType: [''],
      oneTimePrice: [''],
      subscriptionPrice: [''],
      active: ['']
    });
  }

  loadProductPackDataForEditView(): void {     
    if (this.modalViewData.data.id) {      
     this.productService.getProductPackById(this.modalViewData.data.id).subscribe(result => {
        this._productPackModal = Object.assign({}, result.data);  
        this.setProductPackDataForEditView();
      },error=>{
        console.log(error);
      });     
    } else {
      this._productPackModal.active = true;
      this._productPackModal.productId = this.modalViewData.data.modalData.id;  
      this.loadActiveSubscriptionTypes();
    }
    
  }

  setProductPackDataForEditView(): void {     
    if (this.modalViewData.data.id) {    
      this._productPackModal.productId =  this._productPackModal.productId;     
      this.isEdit = true;
    } else {     
      this.isEdit = false;
    }
    this.loadActiveSubscriptionTypes();
  }

  async submit(productPack) {
    if (this.productPackFormGroup.invalid) {
      this.isDisabledProductPack = false;
      return;
    }
    let productPackObj = Object.assign({}, productPack);   
    if (this.isEdit) {
      this.updateProductPack(productPack.id, productPackObj);
    } else {
      this.addProductPack(productPackObj);
    }
  }

  addProductPack(productPackObj) {
    this.isDisabledProductPack = true;
    this.productService.saveProductPackData(productPackObj).subscribe(
      res => {
        if (res.isSuccsess) {
          this.refreshProductPackList(productPackObj.productId);
          this.closeDialog();          
          this.productService.refreshProductPack.emit(true);
        }
      },
      () => {
        console.error(); this.closeDialog();
      });
  }

  updateProductPack(productPackId, productPackObj) {  
    this.productService.updateProductPack(productPackId, productPackObj).subscribe(
      res => {       
        if (res.isSuccsess) {         
          this.refreshProductPackList(productPackObj.productId);
          this.closeDialog();
          this.productService.refreshProductPack.emit(true);    

        }
      },
      () => {
        console.error();
        this.closeDialog();
      });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  activeCheckEvent(event) {
    this._productPackModal.active = event.checked;
  }

  refreshProductPackList(productId) {  
    this.productService.getProductList().subscribe(res => {
      if (res && res.data) {
        this.productService.productList = [];
        this.productService.activeProductList = [];
        this.productService.inActiveProductList = [];
        if (this.productService.isActiveProduct) {        
          this.productService.activeProductList = res.data.filter(x => x.active != null && x.active == this.productService.isActiveProduct);
          this.productService.productList = this.productService.activeProductList;
        }
        if (!this.productService.isActiveProduct) {      
          this.productService.inActiveProductList = res.data.filter(x => x.active == null || x.active == this.productService.isActiveProduct);
          this.productService.productList = this.productService.inActiveProductList;
        }
      }
      else {
        const snack = new SnackBar(SnackBarType.error, 'Error on list', '', 5000);
        this.commonUIService.openSnackBar(snack);
        this.productService.productList = [];
        this.productService.activeProductList = [];
        this.productService.inActiveProductList = [];
      }

      if (this.productService.productList.length > 0) {
        let products = this.productService.productList.find(x => x.id == productId);
        if (products.productPack) {
          this.productService.productPackList = products.productPack;
          if (this.productService.isActiveProductPack) {
            this.productService.activeProductPackList = this.productService.productPackList.filter(x => x.active != null && x.active == this.productService.isActiveProductPack);
            this.productService.productPackList = this.productService.activeProductPackList;
          }
          if (!this.productService.isActiveProductPack) {
            this.productService.inActiveProductPackList = this.productService.productPackList.filter(x => x.active == null || x.active == this.productService.isActiveProductPack);
            this.productService.productPackList = this.productService.inActiveProductPackList;
          }
        }
        else {
          this.productService.productPackList = [];
          this.productService.activeProductPackList = [];
          this.productService.inActiveProductPackList = [];
        }

        const snack = new SnackBar(SnackBarType.success, 'Successfully updated', '', 2000);
        this.commonUIService.openSnackBar(snack); 
      }
    },
      err => { 
        
        console.error(err);
        const snack = new SnackBar(SnackBarType.error, 'Error occurred', '', 5000);
        this.commonUIService.openSnackBar(snack); 
      }, () => { });
  }

  loadActiveSubscriptionTypes(){      
    this.commonDropdownService.loadActiveSubscriptionTypes()
    .subscribe(response => {
      this.commonDropdownService.subscriptionTypeList = response.data;  
      this.subscriptionTypeList = this.commonDropdownService.subscriptionTypeList;
      if(this.isEdit){
        this._productPackModal.subscriptionType = this.modalViewData.data.modalData.subscriptionType!=null ? parseInt(this.modalViewData.data.modalData.subscriptionType) : null;
        if (this.subscriptionTypeList && this.subscriptionTypeList.length > 0) {

          //The subscription type should be 'One Time' by default if the subscription price is null or 0
          if (this._productPackModal.subscriptionPrice == null || this._productPackModal.subscriptionPrice == 0)
          {
            this.subscriptionTypeList = this.subscriptionTypeList.filter(a => a.name.toLowerCase().trim().replace(" ", "") == 'onetime');
            this._productPackModal.subscriptionType = this.subscriptionTypeList && typeof (this.subscriptionTypeList) != 'undefined' && this.subscriptionTypeList.length > 0 ? parseInt(this.subscriptionTypeList[0].id) : null;
            this.isDisabledSubscriptionType = true;
          }
          else
          {
            this.subscriptionTypeList = this.subscriptionTypeList.filter(a => a.name.toLowerCase().trim().replace(" ", "") != 'onetime');
            this._productPackModal.subscriptionType = this.modalViewData.data.modalData.subscriptionType;
            this.isDisabledSubscriptionType = false;
          }          
        }        
      }
      else
      {
        this.isDisabledSubscriptionType = true;
        if(this.subscriptionTypeList && this.subscriptionTypeList.length>0)
        {
           //Since the subscription price is empty on new client pack addition, the subscription type should be 'One Time' by default
          let oneTimeOption = this.subscriptionTypeList.filter(a=>a.name.toLowerCase().trim().replace(" ","")=='onetime');
          this._productPackModal.subscriptionType =oneTimeOption && typeof(oneTimeOption)!='undefined' && oneTimeOption.length>0? parseInt(oneTimeOption[0].id):null;
        }       
      }
    }, err => {
      console.error(err)
    }, () => { });
  }

    //#region "handle subscription type drop down values based on entered Subscription price"

    onSubscriptionPriceChange(inputVal : any)
    {    
      if(inputVal==null || parseInt(inputVal)==0|| inputVal.length==0)
      {
        this._productPackModal.subscriptionType = null;
        this.subscriptionTypeList  = this.commonDropdownService.subscriptionTypeList.filter(a => a.name.toLowerCase().trim().replace(" ", "") == 'onetime');
        this._productPackModal.subscriptionType = this.subscriptionTypeList[0] && typeof(this.subscriptionTypeList[0])!='undefined' && this.commonDropdownService.subscriptionTypeList.length>0? parseInt(this.commonDropdownService.subscriptionTypeList[0].id):null;
        this.isDisabledSubscriptionType = true;
      }
      else
      {
        let selectedSubscriptionType = this._productPackModal.subscriptionType;
        this.subscriptionTypeList  =  this.commonDropdownService.subscriptionTypeList.filter(a => a.name.toLowerCase().trim().replace(" ", "") != 'onetime');
        this.isDisabledSubscriptionType = false;

        //Retain the previously selected value after reloading the drop down
        this._productPackModal.subscriptionType = selectedSubscriptionType;
      }
    }
  
    //#endregion
  


}
