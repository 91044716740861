import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularMaterialModule } from './angular-material.module';
import { AuthModule } from './auth/auth.module';
import { SharedModule } from './shared/shared.module';
import {MatDividerModule} from '@angular/material/divider';
import { AdminModule } from './admin/admin.module';
import {NgbModalConfig, NgbModal, NgbModule, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { CommonModule, DecimalPipe } from '@angular/common';
import { FormatMatDatepicker, FormatTimeForDisplay } from './shared/format-datepicker';
import {FormsModule } from '@angular/forms';
import { MatDialog, MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS } from '@angular/material';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { HttpClientModule } from '@angular/common/http';
import { SocialLoginModule } from "angularx-social-login";
import { FullCalendarModule } from '@fullcalendar/angular';
import { ClientdetailComponent } from './client/client-detail/client-detail.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { SlickCarouselModule } from 'ngx-slick-carousel';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  declarations: [
    AppComponent, 
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SharedModule,
    AuthModule,
    AppRoutingModule,
    AngularMaterialModule,
    MatDividerModule,
    AdminModule,
    NgbModule,
    CommonModule,
    FormsModule,
    SocialLoginModule,
    FullCalendarModule,
    AngularEditorModule,
    SlickCarouselModule,
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
    NgbModalConfig, NgbModal, FormatMatDatepicker, MatDialog, FormatTimeForDisplay, DecimalPipe],
  entryComponents: [],
  bootstrap: [AppComponent]

})
export class AppModule { 
  
}
