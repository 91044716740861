import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MaintenanceService } from '../../service/maintenance.service';
import { ValidationService } from 'src/app/shared/services/validation.service';
import { UIService, DialogDataForModalView } from 'src/app/shared/services/ui.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ReferalSourceModel } from 'src/app/shared/entities/referalSource';
import { SnackBar, SnackBarType } from 'src/app/shared/components/snackbar/entities/snackbar';

@Component({
  selector: 'app-add-edit-referalsource',
  templateUrl: './add-edit-referalsource.component.html',
  styleUrls: ['./add-edit-referalsource.component.scss']
})
export class AddEditReferalsourceComponent implements OnInit {

  referalSourceFormGroup: FormGroup;
  _referalSource: ReferalSourceModel;
  isDisabledReferalSource =false;
  isEdit: boolean = false;

  constructor(
    private _formBuilder: FormBuilder,
    private maintenanceService: MaintenanceService,
    private validationService: ValidationService,
    private uIService: UIService,
    public dialogRef: MatDialogRef<AddEditReferalsourceComponent>,
    @Inject(MAT_DIALOG_DATA) public referalSourceData: DialogDataForModalView
  ) { }

  ngOnInit() {
    this._referalSource = new ReferalSourceModel();
    this.initFormGroup();
    this.setReferalDataForEditView();
    }

  initFormGroup() {
    this.referalSourceFormGroup = this._formBuilder.group({
      refSourceName: ['', [Validators.required, this.validationService.noWhitespaceValidator]],
    });
  }

  setReferalDataForEditView() {
    if (this.referalSourceData.data) {
      this._referalSource = Object.assign({}, this.referalSourceData.data).modalData;
      this.isEdit = true;
    }
  }

  async submit(referalSource: { id: any; }) {
    if (this.referalSourceFormGroup.invalid) {
      this.isDisabledReferalSource = false;
      return;
    }
    if (this.isEdit) this.updateReferalSource(referalSource.id, referalSource);
    else
      this.saveReferalSource(referalSource);
  }


  saveReferalSource(referalSource: any) {
    this.isDisabledReferalSource = true;
    this.maintenanceService.SaveReferalSource(referalSource).subscribe(
      res => {
        if (res.isSuccsess) {
          this.maintenanceService.referalRefresh(true);
          this.closeDialog();
          let snack = new SnackBar(SnackBarType.success, "Successfully saved", "", 2000);
          this.uIService.openSnackBar(snack);
        } else {
          let snack = new SnackBar(SnackBarType.success, "Error on save", "", 2000);
          this.uIService.openSnackBar(snack);
        }
      },
      () => { console.error(); this.closeDialog(); });
  }

  updateReferalSource(id: any, obj: any) {
    this.maintenanceService.updateReferalSource(id, obj).subscribe(
      res => {
        if (res.isSuccsess) {
          this.maintenanceService.referalRefresh(true);
          this.closeDialog();
          let snack = new SnackBar(SnackBarType.success, "Successfully updated", "", 2000);
          this.uIService.openSnackBar(snack);
        } else {
          let snack = new SnackBar(SnackBarType.success, "Error on update", "", 5000);
          this.uIService.openSnackBar(snack);
        }
      },
      () => { console.error(); this.closeDialog(); });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
