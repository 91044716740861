import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { MatDialogRef, MatSelectChange, MAT_DIALOG_DATA } from '@angular/material';
import { DialogDataForModalView, UIService } from 'src/app/shared/services/ui.service';
import { CommonDropdownService } from 'src/app/shared/services/common.dropdown.service';
import { isNumber } from 'util';
import { SnackBarType, SnackBar } from 'src/app/shared/components/snackbar/entities/snackbar';
import { FormatMatDatepicker } from 'src/app/shared/format-datepicker';
import { APIService } from 'src/app/shared/services/api.service';
import API from 'src/app/shared/services/api.routes.json';
import { ValidationService } from 'src/app/shared/services/validation.service';
import { Employee } from '../../../../../entities/empoyee';
import { HrmService } from '../../../../../service/hrm.service';
import { EmployeeLeave } from '../../../../../entities/empoyeeLeave';
import { HrmMetaData } from 'src/app/shared/entities/hrmMetadataModel';
import { HrmMetaDataEnum } from 'src/app/shared/enum/hrmMetadataType';
import moment from 'moment';
declare const JSON;

@Component({
  selector: 'add-edit-employee-leave',
  templateUrl: './add-edit-employee-leave.component.html',
  styleUrls: ['./add-edit-employee-leave.component.scss']
})
export class AddEditEmployeeLeaveComponent implements OnInit {

  employeeLeaveFormGroup: FormGroup;
  _employeeLeaveModal: EmployeeLeave;
  _employeeLeaveForSave: EmployeeLeave;
  selectedEmployee: Employee;
  isChecked = true;
  toggle = true;
  isLeaveRequired = false;
  private _unsubscribeAll: Subject<any>;
  isDisableStartTime = false;
  isDisableEndTime = false;
  isShowIsFullDayLeave = true;
  isDisabledSave = false;
  isAddLeavePopupOpen = true;
  minDate: Date;
  tempEmployeeLeaveModal: EmployeeLeave;
  checkToDate: boolean = false;
  leaveTypeList: HrmMetaData[];
  selectedLeaveType: any;

  constructor(private _apiService: APIService,
    private _formBuilder: FormBuilder,
    private hrmService: HrmService,
    private commonUIService: UIService,
    private validationService: ValidationService,
    private formatDatepicker: FormatMatDatepicker,
    public commonDropdownService: CommonDropdownService,
    public dialogRef: MatDialogRef<AddEditEmployeeLeaveComponent>,
    @Inject(MAT_DIALOG_DATA) public modalViewData: DialogDataForModalView) {
    this._unsubscribeAll = new Subject();
    this._employeeLeaveModal = new EmployeeLeave();
    this._employeeLeaveForSave = new EmployeeLeave();
    this.tempEmployeeLeaveModal = new EmployeeLeave();
  }

  ngOnInit() {
    if (this.modalViewData.data.modalData != null) {
      this._employeeLeaveModal.startDate = this.modalViewData.data.modalData.startDateStr;
      this._employeeLeaveModal.endDate = this.modalViewData.data.modalData.endDateStr;
      this.isShowIsFullDayLeave = !(this._employeeLeaveModal.startDate < this._employeeLeaveModal.endDate);
    }
    this.getLeaveTypeDetails();
    this.initEmployeeLeaveFormGroup();
    this.commonDropdownService.LeaveType();
  }

  initEmployeeLeaveFormGroup() {
    this.employeeLeaveFormGroup = this._formBuilder.group({
      startDate: ['', [Validators.required]],
      endDate: ['', [Validators.required]],
      isFullDayLeave: [''],
      leaveTypeId: ['', [Validators.required]],
      comment: [''],
      startTime: [],
      endTime: [],
      absenceReasonText: ['']
    });
    if (this.modalViewData.data.modalData) {
      this.setValuesForEdit();
    }
  }

  getLeaveTypeDetails() {
    let url = this._apiService.getEndPoint("hrmMetaData.getHrmMetadataByType", [["{type}", HrmMetaDataEnum.Leave], ["{isOnlyActive}", true]]);

    this._apiService.get(url).subscribe(response => {
      if (response) {
        if (response.data) {
          this.leaveTypeList = response.data;
        }
      }
    });
  }

  setValuesForEdit() {
    this._employeeLeaveModal = this.modalViewData.data.modalData;
    if (this.hrmService.isEmployeeLeaveEdit == true) {
      this.tempEmployeeLeaveModal = Object.assign({}, this.modalViewData.data.modalData);
      this._employeeLeaveModal.leaveTypeId = this.modalViewData.data.modalData.leaveTypeId;
      if (this._employeeLeaveModal.startDate < this._employeeLeaveModal.endDate)
        this.isShowIsFullDayLeave = false;
      else
        this.isShowIsFullDayLeave = true;
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  async submit(employeeLeaveModal: EmployeeLeave) {

    this.isDisabledSave = true;
    if (this.employeeLeaveFormGroup.invalid) {
      this.isDisabledSave = false;
      return;
    }
    else {
      let _startDate = employeeLeaveModal.startDate ? this.formatDatepicker.ConvertDateFormatForSaveCalenderEndDate(employeeLeaveModal.startDate) : employeeLeaveModal.startDate;
      let _endDate = employeeLeaveModal.endDate ? this.formatDatepicker.ConvertDateFormatForSaveCalenderEndDate(employeeLeaveModal.endDate) : employeeLeaveModal.endDate;
      let tempStartDate = this.tempEmployeeLeaveModal.startDate ? this.formatDatepicker.ConvertDateFormatForSaveCalenderEndDate(this.tempEmployeeLeaveModal.startDate) : this.tempEmployeeLeaveModal.startDate;
      let tempEndDate = this.tempEmployeeLeaveModal.endDate ? this.formatDatepicker.ConvertDateFormatForSaveCalenderEndDate(this.tempEmployeeLeaveModal.endDate) : this.tempEmployeeLeaveModal.endDate;

      let dayDiff = moment(_endDate).diff(moment(_startDate), 'days', true);

      if (dayDiff <= 1 && !employeeLeaveModal.isFullDayLeave) {
        dayDiff = 0.5
      }else{
        dayDiff = dayDiff + 1;
      }

      if (this.tempEmployeeLeaveModal.startDate || this.tempEmployeeLeaveModal.endDate) {

        this.selectedLeaveType = this.modalViewData.data.type.find(x => x.leaveTypeId === employeeLeaveModal.leaveTypeId)
        if (this.selectedLeaveType.employeeLeaveCategoriesModelList[0].remaining >= dayDiff || this.selectedLeaveType.employeeLeaveCategoriesModelList[0].remaining < 0) {
          this._employeeLeaveForSave = Object.assign({}, employeeLeaveModal);
          this._employeeLeaveForSave.startDateStr = this.modalViewData.data.modalData.startDate ? this.formatDatepicker.ConvertDateFormatForSave(this.modalViewData.data.modalData.startDate) : this.modalViewData.data.modalData.startDate;
          this._employeeLeaveForSave.endDateStr = this.modalViewData.data.modalData.endDate ? this.formatDatepicker.ConvertDateFormatForSave(this.modalViewData.data.modalData.endDate) : this.modalViewData.data.modalData.endDate;
          this._employeeLeaveForSave.leaveTypeId = +this._employeeLeaveForSave.leaveTypeId;
        }
        else {
          let leaveType = this.leaveTypeList.find(x => x.id === employeeLeaveModal.leaveTypeId);
          const snack = new SnackBar(SnackBarType.success, 'Employee has no remaining ' + leaveType.description + ' to add', '', 6000);
          this.commonUIService.openSnackBar(snack);
          this.isDisabledSave = false;
          return;
        }

        this.onSaveEmployeeLeave();
      } else {
        this.isAddLeavePopupOpen = true;
        employeeLeaveModal.startDate = new Date(employeeLeaveModal.startDate);
        employeeLeaveModal.endDate = new Date(employeeLeaveModal.endDate);
        employeeLeaveModal.startDateStr = employeeLeaveModal.startDate ? this.formatDatepicker.ConvertDateFormatForSaveCalenderEndDate(employeeLeaveModal.startDate) : employeeLeaveModal.startDate;
        employeeLeaveModal.endDateStr = employeeLeaveModal.endDate ? this.formatDatepicker.ConvertDateFormatForSaveCalenderEndDate(employeeLeaveModal.endDate) : employeeLeaveModal.endDate;
        //Need for future developments. Don't remove this comment.
        //let d: Date;
        //let isWeekend: boolean;
        //isWeekend = false;
        //d = employeeLeaveModal.startDate;
        //for (d; d < employeeLeaveModal.endDate && !isWeekend; d.setDate(d.getDate() + 1)) {
        //  isWeekend = (d.getDay() === 6) || (d.getDay() === 0);
        //}
        var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        var dStart = new Date(employeeLeaveModal.startDateStr);
        let dayId = dStart.getDay();
        var dayNameStart = days[dayId];
        var dEnd = new Date(employeeLeaveModal.endDateStr);
        var dayNameEnd = days[dEnd.getDay()];
        this.hrmService.getEmployeeLeaveByEmployeeId(employeeLeaveModal.employeeId).subscribe(res => {

          if (res && res.data) {
            this.isAddLeavePopupOpen = true;
            let leaveLeaveList = res.data;
            if (leaveLeaveList.length > 0) {
              leaveLeaveList.forEach(ele => {
                let _startDate = new Date(ele.startDate);
                let _endDate = new Date(ele.endDate);
                let sd = employeeLeaveModal.startDate.toISOString();
                let ed = employeeLeaveModal.endDate.toISOString();
                if (_startDate.toISOString() === sd && _endDate.toISOString() === ed) {
                  this.isAddLeavePopupOpen = false;
                  var snack = new SnackBar(SnackBarType.success, "Employee already added leaves for the selected days", "", 5000);
                  this.commonUIService.openSnackBar(snack);
                  this.isDisabledSave = false;
                }
                else if (sd >= _startDate.toISOString() && ed <= _endDate.toISOString()) {
                  this.isAddLeavePopupOpen = false;
                  var snack = new SnackBar(SnackBarType.success, "Employee already added leaves for the selected days", "", 5000);
                  this.commonUIService.openSnackBar(snack);
                  this.isDisabledSave = false;
                }
                else if (sd === _startDate.toISOString() && ed !== _endDate.toISOString()) {
                  this.isAddLeavePopupOpen = false;
                  var snack = new SnackBar(SnackBarType.success, "Employee already added leaves for the selected days", "", 5000);
                  this.commonUIService.openSnackBar(snack);
                  this.isDisabledSave = false;
                }
                else if (sd !== _startDate.toISOString() && ed === _endDate.toISOString()) {
                  this.isAddLeavePopupOpen = false;
                  var snack = new SnackBar(SnackBarType.success, "Employee already added leaves for the selected days", "", 5000);
                  this.commonUIService.openSnackBar(snack);
                  this.isDisabledSave = false;
                }
                if (sd !== ed) {
                  if (sd >= _startDate.toISOString() && _endDate.toISOString() <= _startDate.toISOString()) {
                    this.isAddLeavePopupOpen = false;
                    var snack = new SnackBar(SnackBarType.success, "Employee already added leaves for the selected days", "", 5000);
                    this.commonUIService.openSnackBar(snack);
                    this.isDisabledSave = false;
                  }
                }
              });
            } else
              this.isAddLeavePopupOpen = true;

            if (dayNameStart == days[6] || dayNameStart == days[0] || dayNameEnd == days[6] || dayNameEnd == days[0]) {
              var snack = new SnackBar(SnackBarType.success, "Add leaves only on weekdays.", "", 5000);
              this.commonUIService.openSnackBar(snack);
              this.isAddLeavePopupOpen = false;
              this.isDisabledSave = false;
            }
            if (this.isAddLeavePopupOpen == false) {
              this.isDisabledSave = false;
              return;
            }
            else {
              employeeLeaveModal.leaveTypeId = +employeeLeaveModal.leaveTypeId;
              if (this.modalViewData.data.type != null) {
                this.selectedLeaveType = this.modalViewData.data.type.find(x => x.leaveTypeId === employeeLeaveModal.leaveTypeId)
                if (this.selectedLeaveType.employeeLeaveCategoriesModelList[0].remaining >= dayDiff) {
                  this.isDisabledSave = true;
                  this._employeeLeaveForSave = Object.assign({}, employeeLeaveModal);
                  this._employeeLeaveForSave.leaveTypeId = +this._employeeLeaveForSave.leaveTypeId;
                  var lt = this._employeeLeaveForSave.leaveTypeId;
                  this._employeeLeaveForSave.leaveTypeText = this._employeeLeaveForSave.leaveTypeText;
                  this._employeeLeaveForSave.startDateStr = employeeLeaveModal.startDate ? this.formatDatepicker.ConvertDateFormatForSave(employeeLeaveModal.startDate) : employeeLeaveModal.startDate;
                  this._employeeLeaveForSave.endDateStr = this.modalViewData.data.modalData.endDate ? this.formatDatepicker.ConvertDateFormatForSave(this.modalViewData.data.modalData.endDate) : this.modalViewData.data.modalData.endDate;
                  this._employeeLeaveForSave.endDate = this.modalViewData.data.modalData.endDate ? this.formatDatepicker.ConvertDateFormatForSaveCalenderEndDate(this.modalViewData.data.modalData.endDate) : this.modalViewData.data.modalData.endDate;
                  this.onSaveEmployeeLeave();
                } else {
                  let leaveType = this.leaveTypeList.find(x => x.id === employeeLeaveModal.leaveTypeId);
                  const snack = new SnackBar(SnackBarType.success, 'Employee has no remaining ' + leaveType.description + ' to add', '', 6000);
                  this.commonUIService.openSnackBar(snack);
                  this.isDisabledSave = false;
                  return;
                }
              } else {
                return;
              }
            }
          }
        });
      }
    }
  }

  async onSaveEmployeeLeave() {
    if (this._employeeLeaveForSave) {
      if (this.modalViewData.data.id) {
        this._employeeLeaveForSave.employeeId = this.modalViewData.data.id;
        this._employeeLeaveForSave.leaveTypeText = this.selectedLeaveType.text;
        this.hrmService.saveEmployeeLeave(this._employeeLeaveForSave).subscribe(
          res => {
            if (res.isSuccsess) {
              const snack = new SnackBar(SnackBarType.success, res.message, '', 4000);
              this.commonUIService.openSnackBar(snack);
              this.hrmService.refershEmloyeeLeaveCalender.emit(this.modalViewData.data.id);
              this.hrmService.refreshLeaveList.emit(true);
              this.closeDialog();
              this.isDisabledSave = false;
            } else {
              this.isDisabledSave = false;
              const snack = new SnackBar(SnackBarType.error, res.message, '', 4000);
              this.commonUIService.openSnackBar(snack);
            }
          },
          err => {
            console.error(); this.closeDialog(); this.isDisabledSave = false;
          });
      }
    }
  }

  closeDialogBox() {
    this.hrmService.cancelLeave.emit(true);
  }

  changeCheckboxValue(event) {
    if (event.checked == true) {
      let startDateString = '2020-08-06T08:00:00';
      let endDateString = '2020-08-06T17:00:00';
      let _startTime = `${new Date(startDateString).getHours()}:${(new Date(startDateString).getMinutes() < 10 ? '0' : '') + new Date(startDateString).getMinutes()}`;
      let _endTime = `${new Date(endDateString).getHours()}:${(new Date(endDateString).getMinutes() < 10 ? '0' : '') + new Date(endDateString).getMinutes()}`;
      this._employeeLeaveModal.startTimeStr = 0 + _startTime;
      this._employeeLeaveModal.endTimeStr = _endTime;
      this.isDisableStartTime = true;
      this.isDisableEndTime = true;
    } else {
      this.isDisableStartTime = false;
      this.isDisableEndTime = false;
    }
  }

  onDateChange(event) {
    if (event.value) {
      if (this._employeeLeaveModal.startDate && this._employeeLeaveModal.endDate) {
        this.checkToDate = !this._employeeLeaveModal.endDate < this._employeeLeaveModal.startDate;
      }
      this.minDate = event.value;
    }
  }

  onLeaveTypeSelected(event: MatSelectChange) {
    this.selectedLeaveType = {
      value: event.value,
      text: event.source.triggerValue
    };
  }
}
