import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Project } from '../../entities/project';
import { ClientService } from '../../services/client.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material';
import { CommonDropdownService } from 'src/app/shared/services/common.dropdown.service';
import { FormatMatDatepicker } from 'src/app/shared/format-datepicker';
import { DialogDataForModalView, UIService } from 'src/app/shared/services/ui.service';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { isNumber } from 'util';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SnackBar, SnackBarType } from 'src/app/shared/components/snackbar/entities/snackbar';
import { ValidationService } from 'src/app/shared/services/validation.service';
import { ProjectViewService } from '../../services/project-view.service';

const techTextChanged = new Subject<string>();

@Component({
  selector: 'app-add-edit-project',
  templateUrl: './add-edit-project.component.html',
  styleUrls: ['./add-edit-project.component.scss']
})
export class AddEditProjectComponent implements OnInit {

  projectFormGroup: FormGroup;
  _project: Project;
  _clientProjectForSave: Project;
  isChecked = true;
  isEdit: boolean = false;
  isSelected = false;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  technologyList: any[] = [];
  allTechnologies: any[] = [];
  autoFilteredTechnologies: any[] = []; //List for auto complete
  chipTechnologies: any[] = []; //list for chip view
  selectedTechValues: string = '';
  techIdList: any[] = [];
  isDisabledProject = false;
  techUpdateList: any[] = [];
  firstOpen: boolean = true;
  isTechNumeric: boolean = false;

  @ViewChild('technologyInput', { read: ElementRef, static: true }) technologyInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', { read: ElementRef, static: true }) matAutocomplete: MatAutocomplete;

  constructor(private _formBuilder: FormBuilder,
    private clientService: ClientService,
    private projectViewService: ProjectViewService,
    private commonUIService: UIService,
    private dialogRef: MatDialogRef<AddEditProjectComponent>,
    public commonDropdownService: CommonDropdownService,
    private validationService: ValidationService,
    private formatDatepicker: FormatMatDatepicker,
    @Inject(MAT_DIALOG_DATA) public modalViewData: DialogDataForModalView) {   
    this._project = new Project();
    this._project.isEdit = (this.modalViewData.data.modalData && this.modalViewData.data.modalData.isEdit) ? this.modalViewData.data.modalData.isEdit :false;
  }

  ngOnInit() {   
    this.firstOpen = true;
    this.initFormGroup();   
    this._clientProjectForSave = new Project();
    techTextChanged.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe((res) => {
      this.loadDataForAutoComplete(res);
    });
  }

  initTagValues() {
    this.commonDropdownService.LoadTechnologyList().subscribe((list) => {
      this.technologyList = list;
      this.allTechnologies = this.technologyList;
      this.setProjectDataForEditView();
    });
  }

  setProjectDataForEditView(): void {   
    if (this.modalViewData.data) {
      this._project = Object.assign({}, this.modalViewData.data.modalData);
      if (isNumber(this.modalViewData.data.id)) {
        this.isEdit = false;
        this._project.isEdit = false;
      }
      else {
        this.isChecked = this.modalViewData.data.status;
        this.isEdit = true;
        this._project.isEdit = true;
        this.setTechnologyValueForEditView();
      }
    }
  }

  initFormGroup() {
    this.projectFormGroup = this._formBuilder.group({
      projectName: ['', [Validators.required, this.validationService.noWhitespaceValidator]],
      projectDate: [''],
      technologies: [''],
      projectDescription: ['', [Validators.required, this.validationService.noWhitespaceValidator]],
      status: [''],
      projectStatusId: ['', [Validators.required]]
    });
    this.initTagValues();
    this.getProjectStatusTypes();
  }

  async submit(clientProject) {
    this.isTechNumeric = false;
    if (this.projectFormGroup.invalid) {
      this.isDisabledProject = false;
      return;
    }
    let clientProjectObj = Object.assign({}, clientProject);
    clientProjectObj.status = true;
    clientProjectObj.projectDateStr = clientProjectObj.projectDate ? this.formatDatepicker.ConvertDateFormatForSave(clientProjectObj.projectDate) : clientProjectObj.projectDate;
    clientProjectObj.projectStatusId = parseInt(clientProjectObj.projectStatusId);

    if (this.isEdit)
      this.updateClientProject(clientProject.id, clientProjectObj);
    else
      this.SaveClientProject(clientProjectObj);

    this.isDisabledProject = true;
  }

  SaveClientProject(ClientProjectObj) {
    ClientProjectObj.status = this.isChecked;
    this.BindToModel(ClientProjectObj);   
    this.clientService.SaveProject(this._clientProjectForSave).subscribe(
      saveResult => {
        if (saveResult.isSuccsess) {     
               
          
          this.clientService.isInactiveProjectStatusType(this._clientProjectForSave.projectStatusId).subscribe(projStatusRes=>
          {                           
              console.log(saveResult.message);//Required to determine whether the selected project status is of inactive type
             
              this.closeDialog(); 
              let snack = new SnackBar(SnackBarType.success, "Successfully saved", "", 2000);
              this.commonUIService.openSnackBar(snack);
               
             
              this.clientService.isToInActive=projStatusRes.data;           
              this.clientService.refresPrject = true;       
              this.clientService.isProjectDeletion = false;             
              this.projectViewService.refreshProject.emit(saveResult.data);
            },error=>
            {
              console.log(error);
              let snack = new SnackBar(SnackBarType.success, "An error occurred while updating the project status.", "", 7000);
              this.commonUIService.openSnackBar(snack);           
            });          
          
        }
        else {
          let snack = new SnackBar(SnackBarType.success, "An error occurred while updating the entries.", "", 7000);
          this.commonUIService.openSnackBar(snack);
          this.closeDialog();
        }
      },
      () => { console.error(); this.closeDialog(); });
  }

  updateClientProject(projectId, projectObj) {
    projectObj.technologyList = [];
    projectObj.technologyListForView = [];
    this.clientService.isProjectDeletion = false;
    console.log(projectObj); //Required to view Project details

    this.clientService.updateProject(projectId, projectObj).subscribe(
      res => {
        if (res.isSuccsess) {
          let clientId = res.data.clientId;
          var snack = new SnackBar(SnackBarType.success, "Successfully updated", "", 3000);
          this.commonUIService.openSnackBar(snack);
          this.closeDialog();       
          this.clientService.refresPrject = false;        
          this.projectViewService.refreshProject.emit(res.data);
        }
      },
      () => { console.error(); this.closeDialog(); });
  }

  BindToModel(projectObj) {
    this._clientProjectForSave = Object.assign({}, projectObj);
    this._clientProjectForSave.clientId = this.modalViewData.data.id;
    this._clientProjectForSave.projectName = projectObj.projectName;
    this._clientProjectForSave.projectDate = projectObj.projectDate;

    this._clientProjectForSave.status = projectObj.status;
    this._clientProjectForSave.projectDescription = projectObj.projectDescription;
    this._clientProjectForSave.technologies = projectObj.technologies;
    this._clientProjectForSave.projectStatusId = projectObj.projectStatusId;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  //#region mat-chip
  remove(tech: any): void {
    const index = this.chipTechnologies.indexOf(tech);
    if (index >= 0) {
      this.chipTechnologies.splice(index, 1);
      this.updateSelectedTechValues(tech, 1);
      this.updateAutoCompleteList(tech, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent, selected: boolean): void {
    if (this.firstOpen == false) {
      this.techIdList.pop();
      this.chipTechnologies.pop();
      this.techUpdateList.pop();
    }

    this.isSelected = selected;
    let listChip: any = [];
    listChip.id = event.option.value;
    listChip.name = event.option.viewValue;
    let color = this.allTechnologies.filter(x => x.id === listChip.id)[0].color;
    let icon = this.allTechnologies.filter(x => x.id === listChip.id)[0].icons;
    listChip.color = color;
    listChip.icons = icon;
    this.chipTechnologies.push(listChip);
    this.technologyInput.nativeElement.value = '';
    this.updateSelectedTechValues(listChip, 0);
    this.updateAutoCompleteList(listChip, 0);
  }

  onEnter(obj) {
    let val = obj.target.value;
    let numbers = /^[0-9]+$/;
    if (val.match(numbers)) {
      obj.target.value = null;
      this.isTechNumeric = true;
      return;
    }
    if (!val.trim()) {
      obj.target.value = null;
      return;
    }

    this.isTechNumeric = false;
    let listChip: any = [];
    listChip.id = val;
    listChip.name = val;
    obj.target.value = null;
    this.chipTechnologies.push(listChip);
    this.updateSelectedTechValues(listChip, 0);
    this.updateAutoCompleteList(listChip, 0);
    this.firstOpen = false;
  }

  searchDataForAutoComplete(event) {
    if (event && event.code != "Space") {
      techTextChanged.next(event);
    }
  }

  loadDataForAutoComplete(event) {
    let value = event.target.value == "" ? null : event.target.value;
    if (value) {
      this.autoFilteredTechnologies = this.allTechnologies.filter(x => x.name.toLowerCase().indexOf(value.toLowerCase()) >= 0);
    }
    else this.autoFilteredTechnologies = [];
  }
  updateAutoCompleteList(list, type) {
    const index = this.allTechnologies.findIndex(x => x.id === list.id);
    if (type === 0) this.allTechnologies.splice(index, 1);
    else if (type === 1) this.allTechnologies.push(list);
  }
  updateSelectedTechValues(item, type) {
    if (this.isEdit == true) {
      if (type === 0) {
        this.techUpdateList.push(item.id);
        this.selectedTechValues = this.techUpdateList.length > 0 ? JSON.stringify(this.techUpdateList).replace(/]|[[]|["]/g, '') : '';
      }
      else if (type === 1) {
        const index = this.techUpdateList.indexOf(item.id.toString());
        this.techUpdateList.splice(index, 1);
        this.selectedTechValues = this.techUpdateList.length > 0 ? JSON.stringify(this.techUpdateList).replace(/]|[[]|["]/g, '') : '';
      }
    }
    else {
      if (type === 0) {
        this.techIdList.push(item.id);
      }
      else if (type === 1) {
        const index = this.techIdList.findIndex(x => x === item.id);
        this.techIdList.splice(index, 1);
      }
      this.selectedTechValues = this.techIdList.length > 0 ? JSON.stringify(this.techIdList).replace(/]|[[]|["]/g, '') : "";
    }
    this._project.technologies = this.selectedTechValues;
  }

  setTechnologyValueForEditView() {
    this.chipTechnologies = this._project.technologyListForView.map((t) => t);
    if (this._project.technologies) {
      this.techUpdateList = this._project.technologies.split(',');
    }
    this.selectedTechValues = this._project.technologies;
    this._project.technologyListForView.forEach(ele => {
      const index = this.allTechnologies.findIndex(x => x.id === ele.id);
      this.allTechnologies.splice(index, 1);
      this.techIdList.push(ele.id);
    });
    this.autoFilteredTechnologies = this.allTechnologies;
  }

  closeDialogBox() {
    this.closeDialog();
  }
  //#endregion

 //#region "Status types"

 getProjectStatusTypes()
 {
   this.commonDropdownService.LoadProjectStatusList();
 }
 
 isInactiveStatusType(statusId){
   let activeStatusList= [1,2,3,4,5,6];
   let isInactiveType = activeStatusList.indexOf(parseInt(statusId))==-1;
   return isInactiveType;
 }

  //#endregion
}
