import { Component, OnInit, Input, Inject, ViewChild, OnChanges } from '@angular/core';
import { CommonDropdownService } from 'src/app/shared/services/common.dropdown.service';
import { UIService, DialogDataForModalView } from 'src/app/shared/services/ui.service';
import { APIService } from 'src/app/shared/services/api.service';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HrmService } from '../../../../service/hrm.service';
import { FormatMatDatepicker } from '../../../../../shared/format-datepicker';
import { ValidationService } from '../../../../../shared/services/validation.service';
import { CalendarOptions } from '@fullcalendar/angular';
import { AddEditEmployeeLeaveComponent } from './add-edit-employee-leave/add-edit-employee-leave.component';
import { Employee } from '../../../../entities/empoyee';
import { EmployeeLeave } from '../../../../entities/empoyeeLeave';
import API from 'src/app/shared/services/api.routes.json';
import { SnackBar, SnackBarType } from '../../../../../shared/components/snackbar/entities/snackbar';
import moment from 'moment';

@Component({
  selector: 'hrm-calender',
  templateUrl: './hrm-calender.component.html',
  styleUrls: ['./hrm-calender.component.scss']
})

export class HRMCalenderComponent implements OnInit, OnChanges {

  @Input() employeeModel: Employee;
  employeeLeaveTypeList: any = [];
  employeeLeaveEventList: any = [];
  leaveLeaveList: any = [];
  isAddLeavePopupOpen = true;
  tempEmployeeLeaveModal: EmployeeLeave;

  calendarOptions: CalendarOptions = {
    initialView: 'dayGridMonth',
    select: this.openAddLeavePopup.bind(this),
    selectable: true,
    //Don't remove this comment. Need for future developments
    //selectAllow: function (select) {
    //  return moment().diff(select.start) <= 0
    //},
    headerToolbar: {
      left: 'prev,next today',
      center: 'title',
      right: ''
    }
  };

  constructor(public commonDropdownService: CommonDropdownService,
    public commonUIService: UIService,
    public _apiService: APIService,
    public hrmService: HrmService,
    public dialog: MatDialog,
    public fb: FormBuilder,
    public formatDatepicker: FormatMatDatepicker,
    private validationService: ValidationService) {
    this.tempEmployeeLeaveModal = new EmployeeLeave();
  }

  ngOnInit() {
    this.employeeModel;
    this.subscribeCancelLeave();
  }

  ngOnChanges() {
    if (this.employeeModel.id) {
      this.getEmployeeLeaveTypeListByEmployeeId(this.employeeModel.id);
      this.getEmployeeLeaveEventListByEmployeeId(this.employeeModel.id);
    }
    this.subscribeRefershEmloyeeLeaveCalender();
  }

  async getEmployeeLeaveTypeListByEmployeeId(employeeId) {
    this.hrmService.getEmployeeLeaveTypeListByEmployeeId(employeeId).subscribe(
      res => {
        if (res) {
          this.employeeLeaveTypeList = res.data;
        } else
          this.employeeLeaveTypeList = [];
      },
      err => { console.error(); });
  }

  async getEmployeeLeaveEventListByEmployeeId(employeeId) {
    this.hrmService.getEmployeeLeaveEventListByEmployeeId(employeeId).subscribe(
      res => {
        if (res && res.data) {
          this.calendarOptions.events = res.data;
        } else
          this.calendarOptions.events = [];
      },
      err => { console.error(); });
  }

  openAddLeavePopup(arg) {

    this.calendarOptions.selectable = false;
    this.isAddLeavePopupOpen = true;
    if (arg.end) {
      arg.end.setDate(arg.end.getDate() - 1);
    }
    let employeeLeaveObj = new EmployeeLeave();
    employeeLeaveObj.startDateStr = arg.startStr;
    employeeLeaveObj.endDateStr = arg.end.toISOString();
    employeeLeaveObj.startDate = arg.start;
    employeeLeaveObj.endDate = arg.end;
    var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    var dStart = new Date(employeeLeaveObj.startDateStr);
    let dayId = dStart.getDay();
    var dayNameStart = days[dayId];
    var dEnd = new Date(employeeLeaveObj.endDateStr);
    var dayNameEnd = days[dEnd.getDay()];

    this.hrmService.getEmployeeLeaveByEmployeeId(this.employeeModel.id).subscribe(res => {
      if (res && res.data) {
        this.isAddLeavePopupOpen = true;
        this.leaveLeaveList = res.data;
        if (this.leaveLeaveList.length > 0) {
          this.leaveLeaveList.forEach(ele => {
            let _startDate = new Date(ele.startDate);
            let _endDate = new Date(ele.endDate);
            let sd = employeeLeaveObj.startDate.toISOString();
            let ed = employeeLeaveObj.endDate.toISOString();
            if (_startDate.toISOString() === sd && _endDate.toISOString() === ed) {
              this.isAddLeavePopupOpen = false;
              this.calendarOptions.selectable = true;
              var snack = new SnackBar(SnackBarType.success, "Employee already added leaves for the selected days", "", 5000);
              this.commonUIService.openSnackBar(snack);
            }
            else if (sd >= _startDate.toISOString() && ed <= _endDate.toISOString()) {
              this.isAddLeavePopupOpen = false;
              var snack = new SnackBar(SnackBarType.success, "Employee already added leaves for the selected days", "", 5000);
              this.commonUIService.openSnackBar(snack);
            }
            else if (sd === _startDate.toISOString() && ed !== _endDate.toISOString()) {
              this.isAddLeavePopupOpen = false;
              var snack = new SnackBar(SnackBarType.success, "Employee already added leaves for the selected days", "", 5000);
              this.commonUIService.openSnackBar(snack);
            }
            else if (sd !== _startDate.toISOString() && ed === _endDate.toISOString()) {
              this.isAddLeavePopupOpen = false;
              var snack = new SnackBar(SnackBarType.success, "Employee already added leaves for the selected days", "", 5000);
              this.commonUIService.openSnackBar(snack);
            }
            if (sd !== ed) {
              if (sd >= _startDate.toISOString() && _endDate.toISOString() <= _startDate.toISOString()) {
                this.isAddLeavePopupOpen = false;
                var snack = new SnackBar(SnackBarType.success, "Employee already added leaves for the selected days", "", 5000);
                this.commonUIService.openSnackBar(snack);
              }
            }            
          });
        } else
          this.isAddLeavePopupOpen = true;

        if (dayNameStart == days[6] || dayNameStart == days[0] || dayNameEnd == days[6] || dayNameEnd == days[0]) {
          var snack = new SnackBar(SnackBarType.success, "Add leaves only on weekdays.", "", 5000);
          this.commonUIService.openSnackBar(snack);
          this.isAddLeavePopupOpen = false;
          this.calendarOptions.selectable = true;
        }
        if (this.isAddLeavePopupOpen == false) {
          this.calendarOptions.selectable = true;
          return;
        }
        else {
          this.hrmService.isEmployeeLeaveEdit = false;
          const dialogViewData = this.commonUIService.setDataForModalDialog(this.employeeModel.id, null, this.employeeLeaveTypeList, employeeLeaveObj, null, null);
          this.commonUIService.openDialog(AddEditEmployeeLeaveComponent, dialogViewData);
        }
      }
    });
  }

  subscribeRefershEmloyeeLeaveCalender() {
    this.hrmService.refershEmloyeeLeaveCalender.subscribe(res => {
      if (res > 0) {
        this.calendarOptions.selectable = true;
        this.getEmployeeLeaveTypeListByEmployeeId(res);
        this.getEmployeeLeaveEventListByEmployeeId(res);
      }
    });
  }

  subscribeCancelLeave() {
    this.hrmService.cancelLeave.subscribe(res => {
      if (res) {
        this.calendarOptions.selectable = true;
      }
    });
  }
}
