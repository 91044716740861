import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { EmployeeDocument } from 'src/app/hrm/entities/empoyeeDocument';
import { APIService } from 'src/app/shared/services/api.service';
import { HrmService } from 'src/app/hrm/service/hrm.service';
import { UIService, DialogDataForModalView } from 'src/app/shared/services/ui.service';
import { ValidationService } from 'src/app/shared/services/validation.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { PersonalDocumentViewComponent } from '../personal-document-view.component';
import { FormatMatDatepicker } from 'src/app/shared/format-datepicker';
import { CommonDropdownService } from 'src/app/shared/services/common.dropdown.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { Subject } from 'rxjs';
import { SnackBar, SnackBarType } from 'src/app/shared/components/snackbar/entities/snackbar';

@Component({
  selector: 'app-add-personal-document',
  templateUrl: './add-personal-document.component.html',
  styleUrls: ['./add-personal-document.component.scss']
})
export class AddPersonalDocumentComponent implements OnInit, OnDestroy  {

  personalDocumentFormGroup: FormGroup;
  _personalEmpDocumentModal: EmployeeDocument;
  _personalEmpDocumentForSave: EmployeeDocument;
  personalDocList: any[] = [];
  isChecked = true;
  toggle = true;
  isDocumentRequired = false;
  private _unsubscribeAll: Subject<any>;

  public PDF = '../../assets/images/config/pdf.png';
  public PNG = '../../assets/images/config/png.png';
  public JPG = '../../assets/images/config/jpg.png';
  public JPEG = '../../assets/images/config/jpg.png';
  public XLS = '../../assets/images/config/xls.png';
  public DOC = '../../assets/images/config/doc.png';

  constructor(
    private _apiService: APIService,
    private _formBuilder: FormBuilder,
    private hrmService: HrmService,
    private commonUIService: UIService,
    private validationService: ValidationService,
    public dialogRef: MatDialogRef<AddPersonalDocumentComponent>,
    @Inject(MAT_DIALOG_DATA) public modalViewData: DialogDataForModalView,
    private formatDatepicker: FormatMatDatepicker,
    public commonDropdownService: CommonDropdownService,
    public alertService: AlertService
  ) {
    this._unsubscribeAll = new Subject();
    this._personalEmpDocumentModal = new EmployeeDocument();
    this._personalEmpDocumentModal = new EmployeeDocument();
  }

  ngOnInit() {
    this.initpersonalDocumentFormGroup();
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  initpersonalDocumentFormGroup() {
    this.personalDocumentFormGroup = this._formBuilder.group({
      documentName: ['', [Validators.required, this.validationService.noWhitespaceValidator]],
      date: ['', [Validators.required]],
      file: [null, [Validators.required]]
    });
  }

  onChooseEmpDocument(event: any): void {
    if (event) {
      let files: any = event.target.files;
      this._personalEmpDocumentModal.fileList = files;
      this.toggle = true;
      this.isDocumentRequired = false;
    }
  }

  async submit(employeeDocumentModal: EmployeeDocument) {
    let valid = this.personalDocumentFormGroup.get('file').touched && this.personalDocumentFormGroup.get('file').valid;
    this.isDocumentRequired = !valid;
    this.toggle = valid;
    if (this.personalDocumentFormGroup.invalid) {
      return;
    }
    this._personalEmpDocumentForSave = Object.assign({}, employeeDocumentModal);
    this._personalEmpDocumentForSave.dateStr = employeeDocumentModal.date ? this.formatDatepicker.ConvertDateFormatForSave(employeeDocumentModal.date) : employeeDocumentModal.date;
    this.onSaveEmployeeDocument();
  }

  async onSaveEmployeeDocument() {
    if (this._personalEmpDocumentForSave) {
      if (this.modalViewData.data.id) {
        if(this.modalViewData.data.type == 10) this.hrmService.isLoading_education = true;
        if(this.modalViewData.data.type == 9) this.hrmService.isLoading_Prior = true;
        
        this._personalEmpDocumentForSave.documentTypeId = 8;
        this._personalEmpDocumentForSave.documentGroupId = this.modalViewData.data.type;
        this._personalEmpDocumentForSave.employeeId = this.modalViewData.data.id;
        if (this._personalEmpDocumentForSave.fileList.length > 0) {
          this._personalEmpDocumentForSave.fileName = (this._personalEmpDocumentForSave.fileList[0]).name;
          this._personalEmpDocumentForSave.extension = ((this._personalEmpDocumentForSave.fileList[0]).name).split('.')[(((this._personalEmpDocumentForSave.fileList[0]).name).split('.')).length - 1];
          this.hrmService.saveEmployeeDocument(this._personalEmpDocumentForSave).subscribe(res => {
            if (res != null && res.data) {
              const formData: FormData = new FormData();
              formData.append('file', this._personalEmpDocumentModal.fileList[0], res.data.generatedFileName);
              formData.append('employeeId', res.data.employeeId.toString());
              formData.append('fileId', res.data.id.toString());
              formData.append('letterTypeId', "0");
              this.uploadEmployeeDocument(formData);
              console.log(formData)
            }
          });
          this.closeDialog();
        }
      }
    }
  }

  uploadEmployeeDocument(formData: FormData) {
    this.hrmService.uploadEmployeeDocument(formData).subscribe(
      res => {
        if (+res == 200 || +(res.statusCode) == 200) {
          this.hrmService.refreshPersonalEmployeeDocumentList.emit({ employeeId: this.modalViewData.data.id, documentGroupId: this.modalViewData.data.type });
          const snack = new SnackBar(SnackBarType.success, 'Successfully saved', '', 4000);
          this.commonUIService.openSnackBar(snack);
          this.closeDialog();

        } else {
          const snack = new SnackBar(SnackBarType.success, 'Error on save', '', 4000);
          this.commonUIService.openSnackBar(snack);
        }
      },
      () => { console.error(); this.closeDialog(); });
  }

  closeDialogBox() {
    this.hrmService.cancelDocument.emit(this.modalViewData.data.type);
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

}
