import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { ClientService } from '../../../services/client.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DialogDataForModalView, UIService } from 'src/app/shared/services/ui.service';
import { CommonDropdownService } from 'src/app/shared/services/common.dropdown.service';
import { ClientDocument } from '../../../entities/clientDocument';
import { SnackBarType, SnackBar } from 'src/app/shared/components/snackbar/entities/snackbar';
import { FormatMatDatepicker } from 'src/app/shared/format-datepicker';
import { APIService } from 'src/app/shared/services/api.service';
import { ValidationService } from 'src/app/shared/services/validation.service';
import { ClientViewService } from '../../../services/client-view.service';
import { DocumentService } from '../../../services/document.service';
import moment from 'moment';

@Component({
  selector: 'app-add-edit-client-document',
  templateUrl: './add-edit-client-document.component.html',
  styleUrls: ['./add-edit-client-document.component.scss']
})
export class AddEditClientDocumentComponent implements OnInit {

  clientDocumentFormGroup: FormGroup;
  _clientDocumentModal: ClientDocument;
  _clientDocumentForSave: ClientDocument;
  toggle = true;
  isDocumentRequired = false;
  private _unsubscribeAll: Subject<any>;

  constructor(private _apiService: APIService,
    private _formBuilder: FormBuilder,
    private clientService: ClientService,
    private clientViewService: ClientViewService,
    private documentService: DocumentService,
    private commonUIService: UIService,
    private validationService: ValidationService,
    public dialogRef: MatDialogRef<AddEditClientDocumentComponent>,
    @Inject(MAT_DIALOG_DATA) public modalViewData: DialogDataForModalView,
    private formatDatepicker: FormatMatDatepicker,
    public commonDropdownService: CommonDropdownService) {
    this._unsubscribeAll = new Subject();
    this._clientDocumentModal = new ClientDocument();
    this._clientDocumentForSave = new ClientDocument();
  }

  ngOnInit() {
    this.initClientDocumentFormGroup();
    this.commonDropdownService.LoadDocumentTypeListForCRM();
  }

  initClientDocumentFormGroup() {
    this.clientDocumentFormGroup = this._formBuilder.group({
      documentName: ['', [Validators.required, this.validationService.noWhitespaceValidator]],
      date: ['', [Validators.required]],
      typeOfDocumentId: ['', [Validators.required]],
      file: [null, [Validators.required]]
    });
    let today = moment();
    let date = moment(today);
    this._clientDocumentModal.date = date;
    if (this.modalViewData.data.projectId != 0)
      this._clientDocumentModal.typeOfDocumentId = this.modalViewData.data.projectId;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  onChooseClientDocument(event: any): void {
    if (event) {
      this._clientDocumentModal.fileList = event.target.files;
      this.toggle = true;
      this.isDocumentRequired = false;
    }
  }

  async submit(clientDocumentModal: ClientDocument) {  
    let valid = this.clientDocumentFormGroup.get('file').touched && this.clientDocumentFormGroup.get('file').valid;
    this.isDocumentRequired = !valid;
    this.toggle = valid;
    if (this.clientDocumentFormGroup.invalid) {
      return;
    }
    this.clientViewService.isLoading_clientDocument = true;
    this._clientDocumentForSave = Object.assign({}, clientDocumentModal);
    this._clientDocumentForSave.dateStr = clientDocumentModal.date ? this.formatDatepicker.ConvertDateFormatForSave(clientDocumentModal.date) : clientDocumentModal.date;
    this.onSaveClientDocument().then();
  }

  async onSaveClientDocument() {
    if (this._clientDocumentForSave) {
      if (this.modalViewData.data.id) {
        this._clientDocumentForSave.clientId = this.modalViewData.data.id;
        if (this._clientDocumentForSave.fileList.length > 0) {
          this._clientDocumentForSave.fileName = (this._clientDocumentForSave.fileList[0]).name;
          this._clientDocumentForSave.extension = ((this._clientDocumentForSave.fileList[0]).name).split('.')[(((this._clientDocumentForSave.fileList[0]).name).split('.')).length - 1];
          this.clientService.saveClientDocument(this._clientDocumentForSave).subscribe(res => {
            if (res) {
              const formData: FormData = new FormData();
              formData.append('file', this._clientDocumentModal.fileList[0], res.data.generatedFileName);
              formData.append('clientId', res.data.clientId.toString());
              formData.append('fileId', res.data.id.toString());
              this.uploadClientDocument(formData, res.data.clientId);
            }
          });
          this.closeDialog();
        }
      }
    }
  }

  uploadClientDocument(formData: FormData, clientId) {
    this.clientService.uploadClientDocument(formData).subscribe(
      res => {
        if (+res == 200 || +(res.statusCode) == 200) {
          this.documentService.newDocument.emit(this._clientDocumentForSave);
          const snack = new SnackBar(SnackBarType.success, 'Successfully saved', '', 4000);
          this.commonUIService.openSnackBar(snack);
          this.closeDialog();
          this.clientViewService.loadClientDocumentList(clientId).then();
        } else {
          const snack = new SnackBar(SnackBarType.success, 'Error on save', '', 4000);
          this.clientViewService.isLoading_clientDocument =false;
          this.clientViewService.loadClientDocumentList(clientId).then();
          this.commonUIService.openSnackBar(snack);
        }
      },
      () => { console.error(); this.closeDialog(); });
  }

  closeDialogBox() {
    if (this.modalViewData && this.modalViewData.data.type == undefined) {
      this.modalViewData.data.type = 0;
    }
    this.documentService.cancelDocument.emit(this.modalViewData.data.type);
  }
}
