import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { UIService } from 'src/app/shared/services/ui.service';
import { MaintenanceService } from 'src/app/maintenance/service/maintenance.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ValidationService } from 'src/app/shared/services/validation.service';
import { CommonDropdownService } from 'src/app/shared/services/common.dropdown.service';
import { MoreFilterModel } from '../entities/moreFilterModel';
import { SharedClientService } from '../services/shared.client.service';
import { SnackBar, SnackBarType } from 'src/app/shared/components/snackbar/entities/snackbar';
import { ClientService } from '../services/client.service';
import { FormatMatDatepicker } from 'src/app/shared/format-datepicker';
import { Users } from 'src/app/auth/entities/user';
import { LoginService } from 'src/app/auth/login/login.service';
import { TaskService } from '../services/task.service';

@Component({
  selector: 'filter-client',
  templateUrl: './filter-client.component.html',
  styleUrls: ['./filter-client.component.scss'],
})
export class FilterclientComponent implements OnInit {
  clientFormGroup: FormGroup;
  currentUsers: Users;

  constructor(
    private maintenanceService: MaintenanceService,
    private _formBuilder: FormBuilder,
    private uIService: UIService,
    public commonDropdownService: CommonDropdownService,
    public dialogRef: MatDialogRef<FilterclientComponent>,
    private validationService: ValidationService,
    private sharedClientService: SharedClientService,
    public clientService: ClientService,
    private formatDatepicker: FormatMatDatepicker,
    private loginService: LoginService,
    public taskService: TaskService
  ) {
    this.initDropdownValues();
  }

  ngOnInit() {
    this.formValidate();
    this.setFilterModelValue();
    this.getCurrentUser().then();
  }

  async getCurrentUser() {
    let jsonString = await this.loginService.getSessionAppUser();
    this.currentUsers = jsonString ? JSON.parse(jsonString) : "";
  }

  setFilterModelValue() {
    const value = localStorage.getItem('filterStorageModel');
    if (value) {
      const obj = JSON.parse(value);
      this.clientService.moreFilterModel.city = obj && obj.city ? obj.city : "";
      this.clientService.moreFilterModel.province = obj && obj.province ? obj.province : "";
      this.clientService.moreFilterModel.refferralSourceId = obj && obj.refferralSourceId ? obj.refferralSourceId : 0;
      this.clientService.moreFilterModel.userId = obj && obj.userId ? obj.userId : 0;
      this.clientService.moreFilterModel.fromDate = obj && obj.fromDate ? obj.fromDate : null;
      this.clientService.moreFilterModel.toDate = obj && obj.toDate ? obj.toDate : null;
      this.clientService.moreFilterModel.delayedDate = obj && obj.delayedDate ? obj.delayedDate : null;
      this.clientService.moreFilterModel.currentTaskUserId = obj && obj.currentTaskUserId ? obj.currentTaskUserId : null;
      this.clientService.moreFilterModel.taskDelay = obj && obj.taskDelay ? obj.taskDelay : false;
      this.clientService.moreFilterModel.myTaskCheck = obj && obj.myTaskCheck ? obj.myTaskCheck : false;
    }
  }

  initDropdownValues() {
    this.commonDropdownService.LoadReferralSourceList();
    this.commonDropdownService.LoadProvinceList();
    this.commonDropdownService.LoadUserList();
  }

  formValidate() {
    this.clientFormGroup = this._formBuilder.group({
      city: [''],
      province: [''],
      refferralSourceId: [''],
      userId: [''],
      toDate: [''],
      fromDate: [''],
      myTaskCheck: [],
      taskDelay: [],
      delayedDate: [''],
      currentTaskUserId: ['']
    })
  }

  delayedCheckEvent(event) {
    this.clientService.moreFilterModel.taskDelay = event.checked;
    this.taskService.isDelayedTask = this.clientService.moreFilterModel.taskDelay;
    if (this.taskService.isDelayedTask) {
      this.clientService.moreFilterModel.fromDate = null;
      this.clientService.moreFilterModel.toDate = null;
    }
    if (this.clientService.moreFilterModel.myTaskCheck) {
      this.clientService.moreFilterModel.userId = this.currentUsers.id;
    } else
      this.clientService.moreFilterModel.userId = null;
  }

  myTaskCheckEvent(event) {
    this.clientService.moreFilterModel.myTaskCheck = event.checked;
    this.clientService.moreFilterModel.userId = event.checked ? this.currentUsers.id : null;
  }

  changeTaskUser(userId) {
    this.clientService.moreFilterModel.myTaskCheck = userId == this.currentUsers.id;
  }

  filterInput(model: MoreFilterModel) {
    model.currentTaskUserId = model.myTaskCheck ? this.currentUsers.id : null;
    model.city = model.city ? model.city : null;
    model.province = model.province ? model.province : null;
    model.refferralSourceId = model.refferralSourceId && model.refferralSourceId > 0 ? model.refferralSourceId : null;
    model.delayedDate = model.taskDelay ? new Date() : null;
    model.delayedDateStr = model.taskDelay && model.delayedDate ? this.formatDatepicker.ConvertDateFormatForSave(model.delayedDate) : model.delayedDate;
    model.fromDateStr = model.fromDate ? this.formatDatepicker.ConvertDateFormatForSave(model.fromDate) : null;
    model.toDateStr = model.toDate ? this.formatDatepicker.ConvertDateFormatForSave(model.toDate) : null;
    let dfrom = new Date(model.fromDate);
    let dto = new Date(model.toDate);
    if (dfrom > dto) {
      this.clientService.isMoreFilterActive = false;
      model.hasMoreFilter = false;
      const snack = new SnackBar(SnackBarType.success, 'Select a valid date range', '', 5000);
      this.uIService.openSnackBar(snack);
      return;
    };

    localStorage.setItem('filterStorageModel', JSON.stringify(model));
    if (model.city || model.province || model.refferralSourceId || model.currentTaskUserId ||
      model.userId || model.toDate || model.fromDate || model.delayedDate) {
      this.clientService.isMoreFilterActive = true;
      model.hasMoreFilter = true;
      this.clientService.moreFilterSelectEmitter.emit(model);
      this.closeDialog();
    }
    else {
      this.clientService.isMoreFilterActive = false;
      model.hasMoreFilter = false;
      const snack = new SnackBar(SnackBarType.success, 'Apply at least one filter', '', 5000);
      this.uIService.openSnackBar(snack);
    }
  }

  filterClosed() {
    const value = localStorage.getItem('filterStorageModel');
    if (!value)
      this.closeDialog();
    else {
      this.clientService.moreFilterModel = new MoreFilterModel();
      localStorage.removeItem("filterStorageModel");
      this.clientService.isMoreFilterActive = false;
      this.taskService.isDelayedTask = false;
      this.clientService.moreFilterSelectEmitter.emit();
      this.closeDialog();
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
