import { Component, OnInit, Input } from '@angular/core';
import { InventoryService } from 'src/app/inventory/service/inventoryService';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SnackBar, SnackBarType } from 'src/app/shared/components/snackbar/entities/snackbar';
import { UIService } from 'src/app/shared/services/ui.service';
import { ProvidersModel } from '../../../entities/providers';
import { CommonDropdownService } from '../../../../shared/services/common.dropdown.service';

@Component({
  selector: 'app-providers',
  templateUrl: './providers.component.html',
  styleUrls: ['./providers.component.scss']
})
export class ProvidersComponent implements OnInit {

  @Input() providerId: any;
  providersListModel: ProvidersModel;
  inventoryProviderForm: FormGroup;
  showEditSave: boolean = false;
  lengthNumber: number = 12;
  isEdit: boolean = false;

  constructor(public inventoryService: InventoryService, public fb: FormBuilder, public commonUIService: UIService,
    public commonDropdownService: CommonDropdownService) {
    this.providersListModel = new ProvidersModel();
    if (this.inventoryProviderForm) {
      this.inventoryProviderForm.get('name').markAsUntouched();
    }
  }

  ngOnInit() {
    this.initInventoryDetailForm();
    this.providerId;
    this.commonDropdownService.LoadCountryList();
    if (this.providerId != 0) { this.loadProvidersList(this.providerId); }
  }

  ngOnChanges() {
    if (this.providerId != 0) {
      this.loadProvidersList(this.providerId);
      this.isEdit = true;
      setTimeout(() => this.inventoryProviderForm.disable(), 2000);
      this.showEditSave = false;
    } else {
      this.providersListModel = new ProvidersModel();
      this.isEdit = false;
      if (this.inventoryProviderForm) {
        this.inventoryProviderForm.enable();
      } else {
        this.inventoryProviderForm = this.fb.group({
          name: ['', [Validators.required]],
          address: [''],
          postalCode: [''],
          city: [''],
          countryId: [''],
          phone: ['', [Validators.pattern(/^(\+\d{1,3}[- ]?)?\d{9}$/)]]
        })
        this.inventoryProviderForm.enable();
      }
      this.showEditSave = true;
    }
    if (this.inventoryProviderForm) {
      this.inventoryProviderForm.get('name').markAsUntouched();
    }
  }

  initInventoryDetailForm() {
    this.inventoryProviderForm = this.fb.group({
      name: ['', [Validators.required]],
      address: [''],
      postalCode: [''],
      city: [''],
      countryId: [''],
      phone: ['', [Validators.pattern(/^(\+\d{1,3}[- ]?)?\d{9}$/)]]
    })
  }

  loadProvidersList(id) {
    this.inventoryService.getByProviderId(id).subscribe(res => {
      this.providersListModel = res.data;
      this.providersListModel = Object.assign({}, this.providersListModel[0]);
    });
  }

  editProviders() {
    if (!this.showEditSave) {
      this.showEditSave = true;
    }

    if (this.inventoryProviderForm.disabled) {
      this.inventoryProviderForm.enable();
    }
  }

  saveProviders(model: ProvidersModel) {
    this.inventoryProviderForm.get('name').markAsTouched();
    if (this.inventoryProviderForm.invalid) {
      return
    } else {
      if (model.name != undefined || model.address != undefined || model.postalCode != undefined || model.city != undefined || model.countryId != undefined || model.phone != undefined) {
        if (model.id) {
          this.inventoryService.updateProvider(model.id, model).subscribe(res => {
            if (res.isSuccsess) {
              var snack = new SnackBar(SnackBarType.success, res.message, '', 2000);
              this.commonUIService.openSnackBar(snack);
              if (res.message == "Sucessfully updated") {
                this.inventoryProviderForm.disable();
                this.showEditSave = false;
                this.loadProvidersList(res.data.id);
                this.inventoryService.refreshProvidersUpdate.emit({ status: true, providerId: res.data.id });
              }
            }
          });
        } else {
          this.inventoryService.saveProvider(model).subscribe(
            (res => {
              if (res.isSuccsess) {
                var snack = new SnackBar(SnackBarType.success, "Successfully saved", "", 2000);
                this.commonUIService.openSnackBar(snack);
                this.inventoryProviderForm.disable();
                this.showEditSave = false;
                this.isEdit = true;
                this.loadProvidersList(res.data.id);
                this.inventoryService.refreshProvidersUpdate.emit({ status: true, providerId: res.data.id });
              }
              else {
                snack = new SnackBar(SnackBarType.success, "Error on save", "", 5000);
                this.commonUIService.openSnackBar(snack);
              }
            }),
            () => { console.error(); });
        }
      } else {
        var snack = new SnackBar(SnackBarType.success, "No data entered for save", "", 5000);
        this.commonUIService.openSnackBar(snack);
        return;
      }
    }
  }

  onKey(numb) {
    if (numb.charAt(0) == "+") {
      this.lengthNumber = 12
    }
    else {
      this.lengthNumber = 9;
    }
  }
}
