import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UIService, DialogDataForModalView } from 'src/app/shared/services/ui.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { SnackBar, SnackBarType } from 'src/app/shared/components/snackbar/entities/snackbar';
import { ValidationService } from 'src/app/shared/services/validation.service';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/auth/login/login.service';
import { Users } from 'src/app/auth/entities/user';
import { CommonDropdownService } from '../../../shared/services/common.dropdown.service';
import moment from 'moment';
import { SharedHeaderService } from '../../../shared/services/shared.header.services';
import { TypeOfDocument } from '../../entities/type-of-document';
import { CRMDocumentTypeService } from '../../service/crm-document-type.service';

@Component({
  selector: 'app-add-edit-crm-document-type',
  templateUrl: './add-edit-crm-document-type.component.html',
  styleUrls: ['./add-edit-crm-document-type.component.scss']
})

export class AddEditCRMDocumentTypeComponent implements OnInit {

  typeOfDocumentFormGroup: FormGroup;
  typeOfDocumentModal: TypeOfDocument;
  isEdit: boolean = false;
  _typeOfDocumentForSave: TypeOfDocument;
  isDisabledTypeOfDocument = false;
  _loggedinUser: Users = new Users();

  constructor(
    private _formBuilder: FormBuilder,
    private _typeOfDocumentService: CRMDocumentTypeService,
    public commonUIService: UIService,
    private uIService: UIService,
    private route: Router,
    private loginServic: LoginService,
    private validationService: ValidationService,
    public sharedHeaderService: SharedHeaderService,
    private commonDropdownService: CommonDropdownService,
    public dialogRef: MatDialogRef<AddEditCRMDocumentTypeComponent>,
    @Inject(MAT_DIALOG_DATA) public modelData: DialogDataForModalView) {   
    this.typeOfDocumentModal = new TypeOfDocument();
    this._typeOfDocumentForSave = new TypeOfDocument();
  }

  ngOnInit() {
    this.initFormGroup();   
    this.commonDropdownService.LoadProvinceList();
    this.commonDropdownService.LoadUserList();
    this.setTypeOfDocumentDataForEditView();
  }

  initFormGroup() {
    this.typeOfDocumentFormGroup = this._formBuilder.group({
      name: ['', [Validators.required, this.validationService.noWhitespaceValidator]],
      code: ['', [Validators.required, this.validationService.noWhitespaceValidator]]
    })
  }

  setTypeOfDocumentDataForEditView() {
    if (this.modelData.data) {
      this.typeOfDocumentModal = Object.assign({}, this.modelData.data).modalData;
      this.isEdit = true;
    }
  }

  async submit(typeOfDocument: { id: any; }) {
    this.isDisabledTypeOfDocument = true;
    if (this.typeOfDocumentFormGroup.invalid) {
      this.isDisabledTypeOfDocument = false;
      return;
    }
    if (this.isEdit) {
      this.updateTypeOfDocument(typeOfDocument.id, typeOfDocument);
    }
    else {
      this.saveTypeOfDocumentData(typeOfDocument);
    }
  }

  saveTypeOfDocumentData(typeOfDocumentObj) {
    this.isDisabledTypeOfDocument = true;
    typeOfDocumentObj.client = false;
    this._typeOfDocumentService.addTypeOfDocumentData(typeOfDocumentObj).subscribe(
      (res: { isSuccsess: any; }) => {
        if (res.isSuccsess) {
          var snack = new SnackBar(SnackBarType.success, "Successfully saved", "", 2000);
          this.uIService.openSnackBar(snack);
          this.closeDialog();
          this._typeOfDocumentService.crmDocumentTypeRefresh.emit(true);
        }
        else {
          snack = new SnackBar(SnackBarType.success, "Error on save", "", 2000);
          this.uIService.openSnackBar(snack);
        }
      },
      () => { console.error(); this.closeDialog(); });
  }

  updateTypeOfDocument(id: any, obj) {
    this._typeOfDocumentService.editTypeOfDocumentData(id, obj).subscribe(
      (res: { isSuccsess: any; }) => {
        if (res.isSuccsess) {
          var snack = new SnackBar(SnackBarType.success, "Successfully updated", "", 2000);
          this.uIService.openSnackBar(snack);
          this.closeDialog();
          this._typeOfDocumentService.crmDocumentTypeRefresh.emit(true);
        }
        else {
          snack = new SnackBar(SnackBarType.success, "Error on update", "", 5000);
          this.uIService.openSnackBar(snack);
        }
      },
      () => { console.error(); this.closeDialog(); });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
